<script setup>


import CrudIndex from '@/components/dashboard/crud/homeDesign/index.vue'
import CrudHeader from '@/components/dashboard/crud/header.vue'
import ProductForm from '@/components/dashboard/forms/home_design_form.vue'



</script>


<template>


  <ProductForm/>

  <CrudHeader title="homeDesign"  :openDialog="$store.homeDesignFormStore.openWithEmptyData"/>


  <CrudIndex/>

</template>


<script>


import {useMeta} from "vue-meta";

export default {
  mounted() {
    useMeta({
      title: this.$t('homeDesign')
    })
  }


}
</script>
<style lang="sass">
.info-cards
  margin: 0 0 0 14px

</style>