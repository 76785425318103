<template>
  <a-modal :footer="null" v-model:visible="formModalStore.showFormModal" >
    <a-form
        :model="formState"
        name="basic"
        layout="vertical"
        autocomplete="off"
        @finish="onFinish(formState)"
    >
      <a-form-item
          :label="$t('designType')"
          name="type"
          :rules="[{ required: true}]"
      >
        <a-radio-group  v-model:value="formState.type">
          <a-radio style="display: flex"  value="products">{{$t('products')}}</a-radio>
          <a-radio style="display: flex"  value="productsBackground">{{$t('productsBackground')}}</a-radio>
          <a-radio style="display: flex"  value="productsBackgroundBrand">{{$t('productsBackgroundBrands')}}</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item
          :label="$t('backgroundColor')"
          name="color"

      >
        <a-popover  trigger="click">
          <template #content>
            <ColorPicker
                :color="formState.color"
                theme="light"
                :sucker-hide="true"
                @changeColor="(color)=>{formState.color=color.hex}"

            />
          </template>

          <div class="picker-container" :style="'background-color:'+formState.color"></div>
        </a-popover>




      </a-form-item>

      <a-form-item
          :label="$t('title')"
          name="title"
      >
        <a-input v-model:value="formState.title"/>
      </a-form-item>

<!--      <a-form-item-->
<!--          v-if="formState.type === 'productsBackgroundBrand' ||formState.type === 'productsBackground' "-->
<!--          label="Alt başlık"-->
<!--          name="subtitle"-->

<!--      >-->
<!--        <a-input v-model:value="formState.subtitle"/>-->
<!--      </a-form-item>-->

      <a-form-item :label="$t('products')" >
        <a-select
            allow-clear
            v-model:value="formState.products"
            show-search
            mode="multiple"
            style="width: 100%"
            label-in-value
            :filter-option="false"
            :not-found-content="fetchingProducts ? undefined : null"
            :options="products"
            @search="searchProduct"
        ></a-select>
      </a-form-item>
      <a-form-item v-if="formState.type === 'productsBackgroundBrand'" :label="$t('brands')">
        <a-select
            allow-clear
            v-model:value="formState.brands"
            show-search
            mode="multiple"
            style="width: 100%"
            label-in-value
            :filter-option="false"
            :not-found-content="fetchingBrands ? undefined : null"
            :options="brands"
            @search="searchBrand"
        ></a-select>
      </a-form-item>

      <a-form-item  :label="$t('category')" >
        <a-select
            allow-clear
            v-model:value="formState.category"
            show-search
            style="width: 100%"
            label-in-value
            :filter-option="false"
            :not-found-content="fetchingCategories ? undefined : null"
            :options="categories"
            @search="searchCategory"
        ></a-select>
      </a-form-item>



      <a-form-item v-if="formState.type === 'productsBackgroundBrand' ||formState.type === 'productsBackground' " name="upload" :label="$t('background')+ ' (900x588)'">
        <a-upload
            v-model:file-list="thumbnailState"
            :show-upload-list="false"
            @change="(uploaded)=>{
              log(uploaded)
              if(uploaded.file.status==='done'){
                this.thumbnailLoading=false
                log(this.thumbnailState)
              }
              if(uploaded.file.status!=='done'){
                this.thumbnailLoading=true
              }
              if(uploaded.file.response){formState.thumbnail = uploaded.file.response.fileName}
            }"
            name="logo"
            action="https://api.elagastro.de/media/upload"
            list-type="picture-card"
        >
          <LoadingOutlined v-if="thumbnailLoading"></LoadingOutlined>
          <div v-else>
            <img style="max-width: 102px;max-height:102px" v-if="formState.thumbnail"
                 :src="'https://cdn.bringsdelivery.de/'+formState.thumbnail" alt="avatar"/>
            <div v-else>
              <PlusOutlined></PlusOutlined>
              <div class="ant-upload-text">{{$t('upload')}}</div>
            </div>
          </div>
        </a-upload>
      </a-form-item>


      <a-button key="submit" type="primary" html-type="submit" :loading="loading">{{ $t('save') }}</a-button>


    </a-form>
  </a-modal>
</template>

<script>
import {defineComponent, reactive} from "vue";
import {FormModalStoreHomeDesign} from "../../../stores/modal";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {get, post} from "../../../helper/api";

import {displayErrorMessage, displaySuccessMessage} from "../../../helper/messages";
import {useForm} from "ant-design-vue/es/form";


import {UploadOutlined, InboxOutlined, PlusOutlined, LoadingOutlined} from '@ant-design/icons-vue';
import { ColorPicker } from 'vue-color-kit'
import 'vue-color-kit/dist/vue-color-kit.css'

export default defineComponent({


  components: {
    QuillEditor,
    UploadOutlined,
    InboxOutlined,
    PlusOutlined,
    LoadingOutlined,
    ColorPicker
  },


  setup() {


    const formModalStore = FormModalStoreHomeDesign()


    const formState = reactive({

      type: 'products',
      title: '',
      color:'#f5f5f5',
      // subtitle: '',
      thumbnail: null,
      category:null

    });

    const {resetFields} = useForm(formState)
    return {formState, formModalStore, resetFields}
  },

  data() {
    return {
      loading: false,
      closeModalAfterSave: true,
      clearDataAfterSave: true,
      thumbnailLoading: false,
      thumbnailState: null,
      quill: null,
      products:[],
      categories:[],
      brands:[],
      fetchingCategories:false,
      fetchingProducts:false,
      fetchingBrands:false,
    }
  },
  created() {
    console.log(this.$refs.quill)
  },
  mounted() {



    console.log(this.$refs)

    this.formModalStore.$subscribe((mutation, state) => {

      if (state.showFormModal) {
        if (state.data) {

          this.formState.id = state.data.key
          this.formState.title = state.data.title ?? ""
          // this.formState.subtitle = state.data.subtitle ?? ""
          this.formState.type = state.data.type ?? "products"
          this.formState.color = state.data.color ?? "#f5f5f5"
          this.formState.thumbnail = state.data.thumbnail
          if(state.data.category){
            this.formState.category = {label:state.data.category.title,value:state.data.category.id}
          }
          if(state.data.products){
            this.formState.products = state.data.products.map((p)=>{
              return {label:p.title,value:p.id}
            })
          }
          if(state.data.brands){
            this.formState.brands = state.data.brands.map((p)=>{
              return {label:p.name,value:p.id}
            })
          }


        }
      } else {
        this.resetFields()
      }


    })
  },

  methods: {
    async searchProduct(value){
      let products = await get('product',{'search':value})
      console.log(products.json.data)
      this.products = products.json.data.map((product)=>{
        return {label:product.title,value:product.id}
      })
    },
    async searchCategory(value){
      let products = await get('category',{'search':value})
      console.log(products.json.data.nonGrouped)
      this.categories = products.json.data.nonGrouped.map((product)=>{
        return {label:product.title,value:product.key}
      })
      console.log(this.categories)
    },
    async searchBrand(value){
      let products = await get('brand',{'search':value})

      this.brands = products.json.data.map((product)=>{
        return {label:product.name,value:product.id}
      })
    },
    log(item) {
      console.log(item)
    },

    async onFinish(formState) {
      this.loading = true
      console.log(formState)

      let result = await post('homecard', formState)
      console.log(result)
      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }
      console.log(result.json)
      if (result.json.status) {
        this.loading = false
        displaySuccessMessage()

        this.$store.homeDesignStore.getItems()
        this.formModalStore.showFormModal = false



      }
    }
  }


})
</script>

<style lang="sass" scoped>

.hu-color-picker

  box-shadow: none
  .color-type
    display: none
.colors .item:nth-child(8n+1)
  margin-left: 8px
.picker-container
  width: 50px
  height: 50px
  border-radius: 8px
  border: 1px solid #e2e2e2
.ant-modal-body
  padding-top: 10px



.modal-footer
  display: flex
  flex-direction: row
  justify-content: end
  position: sticky
  bottom: 0
  padding: 12px
  background-color: white

  button
    margin-left: 12px

</style>