<template>

  <div class="top-buttons">
    <a-button class="mr-3" @click="reOrder=!reOrder" :type="reOrder ? 'primary':''">
      <template #icon>
        <column-height-outlined/>
      </template>
      {{ $t('reOrder') }}
    </a-button>
  </div>


  <div class="home-design">


    <a-tree
        v-if="treeData.length>0"


        :auto-expand-parent="autoExpandParent"
        @select="select"

        @drop="onDrop"
        blockNode
        :draggable="reOrder"
        :tree-data="treeData">

      <template #title="{ title,subtitle,thumbnail,products,key }">

        <div class="home-card-item">
          <div class="left">
            <div v-if="thumbnail" class="home-card-image">
              <img  :src="'https://cdn.bringsdelivery.de/'+thumbnail">
            </div>
            <div class="right">
              <div>{{ title }}</div>
              <div><b>{{ subtitle }}</b></div>
              <div class="product-thumbnails">
                <div v-for="product in products" class="product-image">
                  <img :src="'https://cdn.bringsdelivery.de/'+product.thumbnail">
                </div>
              </div>
            </div>
          </div>



          <div>
            <close-circle-outlined class="icon-hover" @click.stop="removeCard(key)" style="color: red;padding: 12px;font-size: 17px"/>
          </div>



        </div>
      </template>
    </a-tree>
    <a-empty v-if="treeData.length===0"/>


  </div>

</template>

<script>


import SingleItem from "@/components/dashboard/crud/category/single.vue";
import CategoryForm from "@/components/dashboard/forms/category_form.vue"


import {LoadingOutlined, ColumnHeightOutlined} from "@ant-design/icons-vue";
import {ref} from "vue";
import {FormModalStoreHomeDesign} from "../../../../stores/modal";
import {get, post} from "../../../../helper/api";
import {displaySuccessMessage} from "../../../../helper/messages";
import {CloseCircleOutlined} from "@ant-design/icons-vue";


export default {

  setup() {
    const formModalStore = FormModalStoreHomeDesign()
    const loading = ref(false)
    return {loading, formModalStore}
  },

  components: {SingleItem, LoadingOutlined, CategoryForm, ColumnHeightOutlined, CloseCircleOutlined},
  data() {
    return {
      treeData: [],
      treeDataNonGrouped: [],
      treeDataNew: [],
      selectedKeys: [],
      checkedKeys: [],
      expandedKeys: [],
      searchValue: "",
      selectedCategory: {},
      autoExpandParent: true,
      reOrder: false,
      count: 0
    }
  },
  watch: {
    treeDataNew(newV, oldV) {
      this.treeData = newV
      //console.log(newV)

      post('homecard/sequence', {
        tree: newV
      })
    },

    searchValue(oldValue, newValue) {

      this.expandedKeys = this.treeDataNonGrouped.map(item => {
        //console.log(item)


        return null;
      }).filter((item, i, self) => item && self.indexOf(item) === i);


    },
    async checkedKeys(newV, oldV) {
      //console.log('old=' + oldV)
      //console.log('new=' + newV)
      await post('homecard/status', {
        ids: newV,

      })
      displaySuccessMessage()
    }
  },
  methods: {
    onExpand(keys) {
      this.expandedKeys = keys;
      this.autoExpandParent = false;
    },
    select(keys, record) {
      //console.log(record.node.dataRef)
      this.$store.homeDesignFormStore.openWithData(record.node.dataRef)
    },
    onDrop(info) {

      const dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);


      const loop = (data, key, callback) => {
        data.forEach((item, index) => {
          if (item.key === key) {
            return callback(item, index, data);
          }

          if (item.children) {
            return loop(item.children, key, callback);
          }
        });
      };

      const data = [...this.treeData]; // Find dragObject


      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);

        dragObj = item;
      });


      if (!info.dropToGap) {

        // Drop on the content
        loop(data, dropKey, item => {
          item.children = item.children || []; /// where to insert 示例添加到头部，可以是随意位置

          item.children.unshift(dragObj);
        });
      } else if ((info.node.children || []).length > 0 && // Has children
          info.node.expanded && // Is expanded
          dropPosition === 1 // On the bottom gap
      ) {

        loop(data, dropKey, item => {
          item.children = item.children || []; // where to insert 示例添加到头部，可以是随意位置

          item.children.unshift(dragObj);
        });
      } else {

        let ar = [];
        let i = 0;

        loop(data, dropKey, (_item, index, arr) => {
          ar = arr;
          i = index;
        });


        if (dropPosition === -1) {

          ar.splice(i, 0, dragObj);
        } else {

          ar.splice(i + 1, 0, dragObj);
        }

      }


      this.treeData = data;
      this.treeDataNew = data;


    },
    async removeCard(id) {
      await post('homecard/delete', {id: id})
      await this.$store.homeDesignStore.getItems()


    },
    addNewCategory() {
      this.selectedCategory = {}
      this.formModalStore.data = {}
      this.formModalStore.showFormModal = true
    },
    generateTreeData() {
      console.log(this.$store.homeDesignStore.items)
      this.treeData = this.$store.homeDesignStore.items;
      this.treeDataNonGrouped = this.$store.homeDesignStore.items;
      if (this.treeData) {
        this.treeData.map((data) => {
          if (data.active) {
            this.checkedKeys.push(data.id)
          }
        })
      }


    }
  },

  async mounted() {

    this.count = (await get('homecard/counts')).json.total
    if (this.$store.homeDesignStore.items.length === 0) {
      this.loading = true
    }
    this.$store.homeDesignStore.$subscribe((mutation, state) => {


      this.generateTreeData()

    })

    console.log('mount')

    await this.$store.homeDesignStore.getItems()

    console.log(this.treeData)


    this.loading = false


  }


}
</script>

<style lang="sass">

</style>
<style lang="sass">

.icon-hover
  border-radius: 12px
  &:hover
    background-color: #efefef
.top-buttons
  display: flex
  flex-direction: row
  justify-content: end
  align-items: center
  padding: 8px 8px 8px 0

.home-design
  .ant-tree .ant-tree-treenode
    padding: 0
  .ant-tree-list-holder-inner
    flex-flow: row wrap !important
  .ant-tree-switcher
    display: none

  .ant-tree-list
    padding: 16px

  .ant-tree
    background-color: transparent
    padding: 0

  .home-card-item
    background-color: white
    padding: 8px
    border-radius: 5px
    display: flex
    flex-direction: row
    margin-bottom: 8px
    justify-content: space-between
    align-items: center
    .left
      display: flex
      flex-direction: row

    .product-thumbnails
      display: flex
      flex-direction: row

      .product-image
        border-radius: 8px
        width: 40px
        height: 40px
        display: flex
        justify-content: center
        align-items: center

    span
      flex: 1

    .home-card-image
      max-width: 100px
      height: 64px
      display: flex
      justify-content: center
      align-items: center
      margin-right: 8px


</style>