<template>

  <div class=" mt-2 ml-5">
    <div class="columns p-0 m-0 mt-3">

      <div class="column p-0 m-0 mr-1">
        <app-form/>
      </div>
      <div class="column ml-1 p-0 is-justify-content-center is-flex">


        <iframe :key="$store.configStore.iframeUpdate"   v-if="$store.configStore.user.id" width="100%" style="width: calc(85vh*9/16);;max-height: 85vh;aspect-ratio: 9 / 16;margin: 0 12px;" :src="'/web/index.html?id='+$store.configStore.user.id">
        </iframe>

      </div>



    </div>
  </div>


</template>

<script>
import AppForm from '@/components/dashboard/forms/app_form.vue'
import AppPublish from '@/components/dashboard/app_publish.vue'
import {useMeta} from "vue-meta";
import {GraphicDesign,MoreApp} from "@icon-park/vue-next";


export default {
  name: "App",
  components:{AppForm,AppPublish,GraphicDesign,MoreApp},
  data(){
    return {
      userid:null,
      activeKey:"1",
    }
  },
  beforeMount() {
    useMeta({
      title: this.$t('design')
    })
  },
}
</script>

