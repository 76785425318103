<template>
  <a-tag
      style="border:none"
      :color="statusColor(order.status)"
  >

    <dashboard-outlined v-if="order.status === 'preparing'"/>
    <check-outlined v-if="order.status === 'completed'"/>

        <truck class="anticon" theme="filled" v-if="order.status === 'shipping'"/>



    <star-filled v-if="order.status === 'new' || order.status === 'updated' "/>
    <close-outlined v-if="order.status === 'canceled'"/>
    <span style="line-height: 26px" class="ml-2 ">
              {{ $t(order.status) }}
            </span>

  </a-tag>
</template>

<script>
import {
  CheckOutlined,
  StarFilled,
  DashboardOutlined,
    CloseOutlined
} from '@ant-design/icons-vue'
import {Truck} from '@icon-park/vue-next'

export default {
  name: "order_status",
  props: {
    order: Object
  },
  methods: {
    statusColor(status) {
      if (status === 'completed') {
        return 'green'
      }
      if (status === 'canceled') {
        return 'red'
      }
      if (status === 'preparing') {
        return 'blue'
      }
      if (status === 'shipping') {
        return 'purple'
      }
      if (status === 'new' || status === 'updated' ) {
        return 'orange'
      }
      if (status === 'approved') {
        return 'purple'
      }
    },
  },
  components: {CheckOutlined, StarFilled, DashboardOutlined,CloseOutlined,Truck}
}
</script>

<style scoped>

</style>