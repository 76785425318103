<template>
  <div class="columns mb-4">
    <div class="column is-12-tablet is-6-desktop is-4-fullhd">
      <a-spin :spinning="loading" :delay="500">
        <a-card>
          <a-form
              :model="formState"
              name="basic"
              layout="vertical"
              autocomplete="off"
              @finish="onFinish(formState)"
              @finishFailed="onFinishFailed"
          >

            <a-form-item
                :label="$t('businessName')"
                name="businessName"
                :rules="[{ required: true}]"
            >
              <a-input v-model:value="formState.businessName"/>
            </a-form-item>
<!--            <a-form-item-->
<!--                :label="$t('showInApp')"-->
<!--                name="shopInApp"-->

<!--            >-->
<!--            <a-switch v-model:checked="formState.shopInApp" />-->
<!--            </a-form-item>-->
            <a-form-item name="logo" :label="$t('logo')">
              <a-upload
                  v-model:file-list="thumbnailState"
                  :show-upload-list="false"
                  @change="(uploaded)=>{

              if(uploaded.file.status==='done'){
                this.thumbnailLoading=false

              }
              if(uploaded.file.status!=='done'){
                this.thumbnailLoading=true
              }
              if(uploaded.file.response){formState.logo = uploaded.file.response.fileName}
            }"
                  name="logo"
                  action="https://api.elagastro.de/media/upload"
                  list-type="picture-card"
              >
                <LoadingOutlined v-if="thumbnailLoading"></LoadingOutlined>
                <div style="width: 102px;height:102px;display: flex;align-items: center;justify-content: center;padding: 8px" v-else>
                  <img style="max-width: 100%;max-height:100%" v-if="formState.logo"
                       :src="'https://cdn.bringsdelivery.de/'+formState.logo" alt="avatar"/>
                  <div v-else>
                    <PlusOutlined></PlusOutlined>
                    <div class="ant-upload-text">{{$t('upload')}}</div>
                  </div>

                </div>


              </a-upload>
            </a-form-item>
            <a-form-item name="logoSquare" :label="$t('logoSquare')">
              <a-upload
                  v-model:file-list="thumbnail2State"
                  :show-upload-list="false"
                  @change="(uploaded)=>{

              if(uploaded.file.status==='done'){
                this.thumbnail2Loading=false

              }
              if(uploaded.file.status!=='done'){
                this.thumbnail2Loading=true
              }
              if(uploaded.file.response){formState.logoSquare = uploaded.file.response.fileName}
            }"
                  name="logo"
                  action="https://api.elagastro.de/media/upload"
                  list-type="picture-card"
              >
                <LoadingOutlined v-if="thumbnail2Loading"></LoadingOutlined>
                <div style="width: 102px;height:102px;display: flex;align-items: center;justify-content: center;padding: 8px" v-else>
                  <img  style="max-width: 100%;max-height:100%" v-if="formState.logoSquare"
                       :src="'https://cdn.bringsdelivery.de/'+formState.logoSquare" alt="avatar"/>
                  <div v-else>
                    <PlusOutlined></PlusOutlined>
                    <div class="ant-upload-text">{{$t('upload')}}</div>
                  </div>

                </div>


              </a-upload>
            </a-form-item>
            <a-form-item
                :label="$t('currency')"
                name="currency"

            >
              <a-select v-model:value="formState.currency" style="width: 30%">
                <a-select-option v-for="currency in currencies.filter((c)=> getSymbolFromCurrency(c) != null)"
                                 :key="currency" :value="currency">
                  <div class="currency-select">
                    <div class="symbol">{{ getSymbolFromCurrency(currency) }}</div>
                    {{ '  ' + currency }}
                  </div>
                </a-select-option>

              </a-select>
            </a-form-item>





            <div class="is-flex is-justify-content-end">
              <a-button :loading="loading" type="primary" html-type="submit">
                <template #icon>
                  <CheckOutlined/>
                </template>
                {{ $t('save') }}
              </a-button>
            </div>




          </a-form>
        </a-card>
      </a-spin>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "vue";

import currencyCodes from 'currency-codes'
import getSymbolFromCurrency from 'currency-symbol-map'
import {displayErrorMessage} from "@/helper/messages";

const sleep = m => new Promise(r => setTimeout(r, m))
import {ConfigStore} from "@/stores/config";
import {get, post} from "../../../helper/api";
import {CheckOutlined,PlusOutlined,LoadingOutlined} from "@ant-design/icons-vue";
import {displaySuccessMessage} from "../../../helper/messages";


export default defineComponent({
  metaInfo: {
    title: 'user_info_form'
  },
  components: {CheckOutlined,PlusOutlined,LoadingOutlined},
  setup() {
    const configStore = ConfigStore();

    const formState = reactive({
      businessName: '',
      businessSector: '',
      currency:"",
      shopInApp:false
    });


    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      configStore,
      onFinishFailed,
      getSymbolFromCurrency
    };
  },
  mounted() {
    this.currencies = currencyCodes.codes()
    this.getUser()
  },
  data() {
    return {
      loading: false,
      currencies: [],
      thumbnailLoading: false,
      thumbnailState: null,
      thumbnail2Loading: false,
      thumbnail2State: null,

    }
  },
  methods: {

    async getUser(){
      this.loading = true
      let user = await get('config')
      //console.log(user)
      this.formState.currency = user.json.config.currency
      this.formState.shopInApp = user.json.config.shopInApp
      this.formState.businessName = user.json.config.businessName
      if (user.json.config.logo) {
        this.formState.logo = user.json.config.logo
      }
      this.loading = false
    },
    async onFinish() {

      this.loading = true
      await sleep(500)
      let result = await post("config", this.formState)

      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }else{
        displaySuccessMessage()
      }
        this.loading = false
      }

    }
  }


);
</script>

<style lang="sass" scoped>
.currency-select
  display: flex
  flex-direction: row
  .symbol
    width: 40px
    font-weight: bold
</style>