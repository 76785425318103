<script setup>

import CrudHeader from '@/components/dashboard/crud/header.vue'
import CrudIndex from '@/components/dashboard/crud/order/index.vue'
</script>
<script>
import {useMeta} from "vue-meta";

export default {
  data() {
    return {
      items:[]
    }
  },
  mounted() {
    useMeta({
      title: this.$t('orders')
    })
  }
}
</script>


<template>

  <CrudHeader title="orders" />




  <CrudIndex />

</template>



<style lang="sass">
.crud-title
  font-size: 20px

</style>