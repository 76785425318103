<template>
  <a-card  :body-style="{padding:' 12px'}" class="second-color text-color mb-2" >
    <div class="product-item">
      <div class="product-image">
        <img :src="'https://cdn.bringsdelivery.de/'+data.product.thumbnail">
      </div>
      <div class="product-right">
        <div>
          <div class="product-title"><span class="has-text-weight-normal">{{data.quantity}}x</span>   {{ data.product.title }}</div>
        </div>

        <div class="left-bottom">
          <div class="primary-color">{{((data.price)/100).toFixed(2)}}<span class="ml-1">{{$store.configStore.user.config.currency}}</span></div>
        </div>
        <div v-if="data.note" style="margin: 8px 8px 8px 0">
          <span class="note">
            {{data.note}}
          </span>

        </div>

      </div>



    </div>

  </a-card>
</template>

<script>
export default {
  name: "single_product",
  props:{
    data:Object
  },
}
</script>

<style lang="sass" scoped>
.quantity-controls
  display: flex
  flex-direction: row
  align-items: center
  margin-right: 8px

.product-item
  display: flex
  flex-direction: row
  .product-right
    flex: 1
    display: flex
    flex-direction: column
    justify-content: space-between
    .note
      background-color: rgb(237, 237, 237)
      border-radius: 5px
      padding: 8px
      width: auto
    .left-bottom
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between

    .product-title
      font-weight: 500
      margin-bottom: 4px


  .product-image
    width: 70px
    height: 70px
    margin-right: 12px
    img
      max-width: 100%
      max-height: 100%
      box-sizing: border-box
      object-fit: cover
      border-radius: 4px
</style>