
<script setup >
import Navbar from '@/components/navbar.vue'
import LoginForm from '@/components/dashboard/forms/login_form.vue'
</script>
<template>
  <Navbar />
  <div class="page-start">
    <div class="container is-fullhd">
      <div class="login">


      <div class="columns">
        <div class="box login-box column is-6 is-4-fullhd">
          <h1 class="title is-3 has-text-centered">{{$t('login')}}</h1>

          <LoginForm />
        </div>
      </div>
      </div>
    </div>
  </div>


</template>
<script>
import {useMeta} from "vue-meta";

export default {
  mounted() {
    useMeta({
      title: this.$t('login')
    })
  }
}
</script>


