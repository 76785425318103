<script setup>
import UserInfoForm from "@/components/dashboard/forms/user_info_form.vue";
</script>
<template>
  <div class="page-start">

    <div class="container is-fullhd login">
      <div class="columns">
        <div class="box login-box column is-6 is-4-fullhd">
          <div class="top-area">

            <h1 class="title is-4">{{$t('yourCompany')}}</h1>
          </div>
          <UserInfoForm/>
          <div  class="is-flex is-justify-content-center ">
            <router-link :to="{name:'dashboard_home'}">
              <a-button type="text">{{$t('later')}}<RightOutlined/></a-button>
            </router-link>
          </div>





        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {RightOutlined} from "@ant-design/icons-vue";

export default {
  components:{RightOutlined},
  name: "user_info"
}
</script>

<style lang="sass" scoped>

.top-area
  display: flex
  flex-direction: row
  margin-bottom: 35px
  align-items: center
  justify-content: center

</style>