<template>
  <div class="columns mb-4">
    <div class="column">
      <a-spin :spinning="loading" :delay="500">
        <a-card>
          <a-form
              :model="formState"
              name="basic"
              layout="vertical"
              autocomplete="off"
              @finish="onFinish(formState)"
              @finishFailed="onFinishFailed"
          >




            <a-form-item

                :name="type"


            >
              <a-spin :spinning="loading">
                <QuillEditor style="min-height: 200px" toolbar="full" :ref="'quill'+type" :id="'quill'+type" contentType="html"
                             v-model:content="formState[type]" theme="snow"/>
              </a-spin>


            </a-form-item>

            <div class="is-flex is-justify-content-end">
              <a-button :loading="loading" type="primary" html-type="submit">
                <template #icon>
                  <CheckOutlined/>
                </template>
                {{ $t('save') }}
              </a-button>
            </div>




          </a-form>
        </a-card>
      </a-spin>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "vue";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import currencyCodes from 'currency-codes'
import getSymbolFromCurrency from 'currency-symbol-map'
import {displayErrorMessage} from "@/helper/messages";

const sleep = m => new Promise(r => setTimeout(r, m))
import {ConfigStore} from "@/stores/config";
import {get, post} from "../../../helper/api";
import {CheckOutlined} from "@ant-design/icons-vue";
import {displaySuccessMessage} from "../../../helper/messages";


export default defineComponent({
  metaInfo: {
    title: 'user_info_form'
  },
  props:{
    type:String
  },
  components: {CheckOutlined,QuillEditor},
  setup() {
    const configStore = ConfigStore();

    const formState = reactive({

    });


    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      configStore,
      onFinishFailed,
      getSymbolFromCurrency
    };
  },
  async mounted() {
    let user = await get('user/documents')
    this.formState[this.type] = user.json[this.type]
    this.loading = false
    await this.$nextTick(() => {
      let self = this
      self.$refs['quill'+this.type].setHTML( this.formState[this.type] ?? '')
    });
  },
  data() {
    return {
      loading: true,

    }
  },
  methods: {


    async onFinish() {

      this.loading = true
      await sleep(500)
      let postData = {}
      postData[this.type] =  this.formState[this.type]
      let result = await post("updateDocuments", postData)

      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }else{
        displaySuccessMessage()
      }
        this.loading = false
      }

    }
  }


);
</script>

<style lang="sass" scoped>
.currency-select
  display: flex
  flex-direction: row
  .symbol
    width: 40px
    font-weight: bold
</style>