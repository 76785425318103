<template>
  <appstore_form/>
  <google_play_modal/>
  <div class="columns m-0 p-0 mt-3">
    <div class="column m-0 p-0 is-6-fullhd is-8-desktop is-12-tablet">
      <transition name="fade" mode="out-in">

          <div v-if="level===1">

            <a-card v-if="$store.configStore.user.config.shopInApp && !subscribeExpired "   class="mb-4 mr-4 type-select no-hover">
              <div class="is-flex is-flex-direction-row is-align-items-center">
                <div class="is-flex-grow-1">
                  <check-circle-outlined class="has-text-success mr-2" />
                  {{$t('publishOnAppmee')}}

                </div>
                <a-button :loading="loading" @click="unPublishNow" type="danger">
                  {{$t('unPublish')}}
                </a-button>
              </div>
            </a-card>

            <a-card v-else  @click="selected='appmee'" :class="selected==='appmee' ? 'selected':''" class="mb-4 mr-4 type-select">
              {{$t('publishAppmee')}}
            </a-card>

            <a-card v-if="requestStatus == null" @click="selected='store'"  :class="selected==='store' ? 'selected':''" class="mb-4 mr-4  type-select">

              {{$t('publishOwnName')}}



            </a-card>
            <a-card v-if="requestStatus === 'new'"  :class="selected==='store' ? 'selected':''" class="mb-4 mr-4  type-select no-hover">
              <clock-circle-outlined style="color: orange" class=" mr-2" />
              {{$t('processingApp')}}


            </a-card>
            <a-card v-if="requestStatus === 'completed'"  :class="selected==='store' ? 'selected':''" class="mb-4 mr-4  type-select no-hover">
              <check-circle-outlined  class="has-text-success mr-2" />
              {{$t('processingAppOk')}}


            </a-card>
          </div>
          <div v-else>
            <div v-if="selected==='appmee'">
              <a-card class="mb-4 mr-4 ">

                <div v-if="$store.configStore.user.package === 'test' ||  $store.configStore.user.package === 'basic' || subscribeExpired" class="is-flex is-flex-direction-row is-align-items-center">
                  <div class="is-flex-grow-1">
                    <close-circle-outlined class="has-text-danger mr-2" />
                    {{$t('packageNotOkPremium')}}

                  </div>
                  <router-link :to="{name:'packages'}" >
                  <a-button  type="primary">
                    {{$t('upgrade')}}
                  </a-button>
                  </router-link>
                </div>

                <div v-else class="is-flex is-flex-direction-row is-align-items-center">
                  <div class="is-flex-grow-1">
                    <check-circle-outlined class="has-text-success mr-2" />
                    {{$t('packageOk')}}
                  </div>
                </div>
              </a-card>

              <div class="is-flex is-justify-content-space-between mr-4">
                <a-button @click="previous">
                  <template #icon>
                    <left-outlined/>
                  </template>
                  {{$t('back')}}
                </a-button>
                <a-button @click="publishNow" :loading="loading" :disabled="$store.configStore.user.package === 'test' ||  $store.configStore.user.package === 'basic'"  type="primary">
                  <template #icon>
                    <check-outlined/>
                  </template>
                  {{$t('publishNow')}}
                </a-button>
              </div>


            </div>

            <div v-else>
              <a-card class="mb-4 mr-4 ">

                <div v-if="$store.configStore.user.package === 'test' ||  $store.configStore.user.package === 'basic'||  $store.configStore.user.package === 'premium' || subscribeExpired" class="is-flex is-flex-direction-row is-align-items-center">
                  <div class="is-flex-grow-1">
                    <close-circle-outlined class="has-text-danger mr-2" />
                    {{$t('packageNotOk')}}

                  </div>
                  <router-link :to="{name:'packages'}" >
                    <a-button  type="primary">
                      {{$t('upgrade')}}
                    </a-button>
                  </router-link>
                </div>

                <div v-else class="is-flex is-flex-direction-row is-align-items-center">
                  <div class="is-flex-grow-1">
                    <check-circle-outlined class="has-text-success mr-2" />
                    {{$t('packageOk')}}
                  </div>
                </div>
              </a-card>

              <a-card class="mb-4 mr-4 ">
                <div class="is-flex is-flex-direction-row is-align-items-center">
                  <div class="is-flex-grow-1">
                    <close-circle-outlined v-if="$store.configStore.user.config.appstoreInfo == null || $store.configStore.user.config.appstoreInfo.p8 == null" class="has-text-danger mr-2" />
                    <check-circle-outlined v-else class="has-text-success mr-2" />

                    {{$t('appstorePlayInfo')}}
                  </div>
                  <a-button @click="formModalStore.openWithData($store.configStore.user.config.appstoreInfo)" type="primary">
                    {{$t('edit')}}
                  </a-button>
                </div>
              </a-card>
              <a-card class="mb-4 mr-4 ">
                <div class="is-flex is-flex-direction-row is-align-items-center">
                  <div class="is-flex-grow-1">
                    <check-circle-outlined class="has-text-success mr-2" />
                    {{$t('googlePlayInfo')}}
                  </div>
                  <a-button @click="modalStoreGooglePlay.openWithEmptyData()" type="primary">
                    {{$t('edit')}}
                  </a-button>
                </div>
              </a-card>

              <div class="is-flex is-justify-content-space-between mr-4">
                <a-button @click="previous">
                  <template #icon>
                    <left-outlined/>
                  </template>
                  {{$t('back')}}
                </a-button>
                <a-button @click="createAppRequest" :loading="loading" :disabled="$store.configStore.user.package === 'test' ||  $store.configStore.user.package === 'basic'||  $store.configStore.user.package === 'premium' || $store.configStore.user.config.appstoreInfo == null || $store.configStore.user.config.appstoreInfo.p8 == null"  type="primary">
                  <template #icon>
                    <check-outlined/>
                  </template>
                  {{$t('publishNow')}}

                </a-button>
              </div>
            </div>

          </div>


      </transition>

      <div v-if="level === 1" class="is-flex is-justify-content-end mr-4">

        <a-button :disabled="selected===''" @click="next" type="primary">
          <template #icon>
            <right-outlined/>
          </template>
          {{$t('continue')}}
        </a-button>
      </div>

    </div>
  </div>

  <a-modal v-model:visible="successModal" :footer="false">
    <div class="has-text-centered">
      <check-circle-outlined class="has-text-weight-medium has-text-success is-size-1"/>
      <div class="mt-2">
        {{$t('requestProcessed ')}}

      </div>

    </div>
  </a-modal>

</template>

<script>
import Appstore_form from "./forms/appstore_form.vue";
import google_play_modal from "./modals/google_play_modal.vue";
import {RightOutlined,LeftOutlined,CloseCircleOutlined,CheckCircleOutlined,CheckOutlined,ClockCircleOutlined} from "@ant-design/icons-vue";
import dayjs from "dayjs";
import {get, post} from "../../helper/api";
import {FormModalStoreAppstore,ModalStoreGooglePlay} from "../../stores/modal";
import {displayErrorMessage} from "../../helper/messages";
export default {
  name: "app_publish",
  components:{ClockCircleOutlined,RightOutlined,LeftOutlined,CloseCircleOutlined,CheckCircleOutlined,CheckOutlined,Appstore_form,google_play_modal},
  setup(){
    const formModalStore = FormModalStoreAppstore()
    const modalStoreGooglePlay = ModalStoreGooglePlay()
    return {formModalStore,modalStoreGooglePlay}
  },
  data(){
    return {
      selected:'',
      level:1,
      subscribeExpired:false,
      loading:false,
      requestStatusLoading:false,
      requestStatus:null,
      successModal:false


    }

  },
  mounted() {
    this.getRequestStatus()

    let subEnd = dayjs(this.$store.configStore.user.subEnd)
    //console.log(subEnd)
    //console.log(dayjs())
    this.subscribeExpired = subEnd.isBefore(dayjs())

  },
  methods:{
    async getRequestStatus(){
      this.requestStatusLoading = true
      let result = await get('appRequestInfo')
      let lastRequest = result.json.lastRequest
      if(lastRequest){
        this.requestStatus = lastRequest
      }else{
        this.requestStatus = ''
      }
      this.requestStatusLoading = false

    },
    async publishNow(){
      this.loading = true
      await this.updateShowInApp(true);
      await this.$store.configStore.getCurrentUser()
      this.level=1;
      this.selected=''
      this.loading = false
    },
    async createAppRequest(){
      this.loading = true
      let result = await post('appRequest',{})
      if(result.ok || result.json.status){
        this.successModal=true
        this.getRequestStatus()
      }else {
        displayErrorMessage()
      }
      this.level=1;
      this.selected=''

      this.loading = false
    },
    async unPublishNow(){
      this.loading = true
      await this.updateShowInApp(false);
      await this.$store.configStore.getCurrentUser()
      this.level=1;
      this.loading = false
      this.selected = ''
    },
    next(){
      this.level=this.level+1
    },
    previous(){
      this.level=this.level-1
      //console.log(this.level)
    },
    async updateShowInApp(shopInApp){


      await post('config',{shopInApp:shopInApp})



    }

  }
}
</script>
<style>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
<style lang="sass" scoped>
.type-select
  transition: 0.5s
  cursor: pointer
  &.selected
    background-color: #039e0d
    color: white
  &:hover
    box-shadow: 10px 0 21px #d5d5d5
.no-hover
  &:hover
    box-shadow: none
    cursor: auto
</style>