<script setup>



</script>
<template>
  <canvas style="height: 200px" :id="id"></canvas>

</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: "PieChart",
  props: {
    id: String,
    data:Object
  },

  mounted() {
    let dates = []
    let datesWithYear = []
    for (let k = 7; k > 0; k--) {
      let date = new Date(new Date().setDate(new Date().getDate() - k))
      datesWithYear.push(date.getFullYear()+'-'+(date.getMonth() + 1).toString().padStart(2, "0")+'-'+(date.getDate() + 1).toString().padStart(2, "0") )
      dates.push((date.getDate() + 1).toString().padStart(2, "0") + '/' + (date.getMonth() + 1).toString().padStart(2, "0"))
    }
    console.log(this.data)
    let paypal = 0
    let stripe = 0
    // let cash = 0
    this.data.forEach((method)=>{
      if(method.paymentMethod === 'Stripe'){
        stripe +=method.count
      }
      if(method.paymentMethod === 'Paypal'){
        paypal +=method.count
      }
    })
    new Chart(
        document.getElementById(this.id),
        {
          type: 'pie',
          data: {
            labels: ['Stripe','Paypal'],
            datasets: [{
              fill: true,

              backgroundColor: [
                '#635bff','#0098e0','#029602'
              ],
              data: [stripe,paypal],
            }]
          },
          options: {
            elements: {
              line: {
                tension: 0.4
              }
            },

            maintainAspectRatio: false,
            interaction: {
              intersect: false,
            },

          }
        }
    );
  },
  data() {
    return {

    }
  }

}


</script>

<style scoped>

</style>