<template>
  <a-form
      :model="formState"
      name="basic"
      layout="vertical"
      autocomplete="off"
      @finish="onFinish(formState)"
      @finishFailed="onFinishFailed"
  >

    <div class="has-text-grey mb-3 has-text-centered">{{ infoText }}</div>

    <Transition mode="out-in">


      <div v-if="step===1">
        <a-form-item
            :label="$t('email')"
            name="email"
            :rules="[{ required: true }]"
        >
          <a-input v-model:value="formState.email"/>
        </a-form-item>
      </div>
      <div v-else-if="step===2">
        <a-form-item
            :label="$t('code')"
            name="code"
            :rules="[{ required: true }]"
        >
          <a-input v-model:value="formState.code"/>
        </a-form-item>
      </div>
      <div v-else-if="step===3">
        <a-form-item
            :label="$t('newPassword')"
            name="password"
            :rules="[{ required: true }]"
        >
          <a-input v-model:value="formState.password"/>
        </a-form-item>
      </div>
    </Transition>


    <a-form-item>
      <a-button :loading="loading" class="login-form-button" type="primary" html-type="submit">{{$t('continue')}}</a-button>

    </a-form-item>
  </a-form>
</template>

<script>
import {defineComponent, reactive} from "vue";
import {get, post} from "../../../helper/api";
import {message} from 'ant-design-vue';
import {displayErrorMessage, displaySuccessMessage} from "../../../helper/messages";

const sleep = m => new Promise(r => setTimeout(r, m))
import {ConfigStore} from "@/stores/config";


export default defineComponent({
  metaInfo: {
    title: 'login_form'
  },
  setup() {
    const configStore = ConfigStore();

    const formState = reactive({
      email: '',
      code:'',
      password: ''
    });


    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      configStore,
      onFinishFailed,
    };
  },

  data() {
    return {
      loading: false,
      infoText: "",
      step: 1
    }
  },
  mounted() {
    this.step= 1
    this.infoText = this.$t('weSentCode')
  },
  methods: {


    async onFinish(formState) {

      this.loading = true
      await sleep(500)
      let result
      if(this.step === 1){
         result = await post("sendCode", {
          "email": formState.email
        })
        this.loading = false
        if (!result.json.error) {
          this.step = 2
          this.infoText = this.$t('writeCode')
          return ;
        }
      }

      if(this.step === 2){
        result = await post("verifyCode", {
          "email": formState.email,
          "code":formState.code
        })
        this.loading=false
        if (!result.json.status) {
          displayErrorMessage(this.$t('codeFalse'))

          return ;
        }else{
          localStorage.setItem('tempToken',result.json.token)
          this.step = 3
          this.infoText = this.$t('writeNewPassword')
          return ;
        }

      }

      if(this.step === 3){
        if(formState.password.length<6){
          displayErrorMessage(this.$t('least6Char'))
          this.loading=false
          return ;

        }
        result = await post("resetPassword", {
          "email": formState.email,
          "code": formState.code,
          "password": formState.password,
        })

        if(result.json.status){
          displaySuccessMessage(this.$t('passwordReset'))
          this.$router.push({name:'login'})
        }
      }


      if (!result.ok || result.json.error) {
        displayErrorMessage()
        this.loading = false
      }





    }
  }


});


</script>
<style lang="sass">
.login-form-button
  width: 100%

.vertical-divider
  display: flex
  justify-content: center
  color: gray
  margin: 12px 0

.login

  @media (min-width: 768px)
    margin: 35px

  .columns
    display: flex
    justify-content: center

  .login-box
    border: 1px solid #d8d8d8
    padding: 35px 35px 12px 35px

    .login-form-button
      margin-top: 25px


    .icon
      font-size: 16px
      margin-right: 8px


</style>



