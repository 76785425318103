<template>
  <a-modal :footer="null" v-model:visible="formModalStore.showFormModal" >

      <div class="has-text-centered mt-2">
        {{$t('givePermission')}}
        <div class="mt-2 has-text-weight-medium">
          bringssystems@gmail.com
        </div>
      </div>
    <div class="has-text-grey has-text-centered mt-2 mb-2 divider-container">
      <div class="divider"></div>
      <div class="divider-content">
        {{$t('or')}}
      </div>

      <div class="divider"></div>
    </div>

    <a-form
        :model="formState"
        name="basic"
        layout="vertical"
        autocomplete="off"
        @finish="onFinish(formState)"
    >
      <a-form-item :rules="[{ required: true}]" name="googlePlayCredentials" label=".json datei">
        <a-upload
            v-model:file-list="thumbnailState"

            @change="(uploaded)=>{
              log(uploaded)
              if(uploaded.file.status==='done'){
                this.thumbnailLoading=false
                log(this.thumbnailState)
              }
              if(uploaded.file.status!=='done'){
                this.thumbnailLoading=true
              }
              if(uploaded.file.response){formState.googlePlayCredentials = uploaded.file.response.fileName}
            }"
            name="logo"
            action="https://api.elagastro.de/media/upload"

        >
          <LoadingOutlined v-if="thumbnailLoading"></LoadingOutlined>
          <div v-else>


            <a-button class="mb-1">
              <upload-outlined></upload-outlined>
              {{ formState.googlePlayCredentials ? $t('update'):$t('upload')}}
            </a-button>

          </div>


        </a-upload>
      </a-form-item>
      <a-button key="submit" type="primary" html-type="submit" :loading="loading">{{ $t('save') }}</a-button>

    </a-form>

  </a-modal>



</template>

<script>
import {defineComponent, reactive} from "vue";
import { ModalStoreGooglePlay} from "../../../stores/modal";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {post} from "../../../helper/api";

import {displayErrorMessage, displaySuccessMessage} from "../../../helper/messages";
import {useForm} from "ant-design-vue/es/form";


import {UploadOutlined, InboxOutlined, PlusOutlined, LoadingOutlined} from '@ant-design/icons-vue';


export default defineComponent({


  components: {
    QuillEditor,
    UploadOutlined,
    InboxOutlined,
    PlusOutlined,
    LoadingOutlined,
  },


  setup() {


    const formModalStore = ModalStoreGooglePlay()


    const formState = reactive({
      googlePlayCredentials: null
    });

    const {resetFields} = useForm(formState)
    return {formState, formModalStore, resetFields}
  },

  data() {
    return {
      loading: false,
      closeModalAfterSave: true,
      clearDataAfterSave: true,
      thumbnailLoading: false,
      thumbnailState: null,
      quill: null
    }
  },
  created() {
    console.log(this.$refs.quill)
  },
  mounted() {


    console.log(this.$refs)

    this.formModalStore.$subscribe((mutation, state) => {

      if (state.showFormModal) {
        if (state.data) {

          this.formState.id = this.$store.configStore.user.config.id
          if(this.$store.configStore.user.config.googlePlayCredentials){
            this.thumbnailState = [
              {
                uid: '1',
                name: this.$store.configStore.user.config.googlePlayCredentials,
                status: 'done',


              }
            ]

          }


        }
      } else {
        this.resetFields()
      }


    })
  },

  methods: {
    log(item) {
      console.log(item)
    },

    async onFinish(formState) {
      this.loading = true
      console.log(formState)

      let result = await post('config', formState)
      console.log(result)
      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }
      console.log(result.json)
      if (result.json.status) {
        this.loading = false
        displaySuccessMessage()

        this.$store.brandStore.getItems()



      }
    }
  }


})
</script>

<style lang="sass" scoped>
.divider-container
  display: flex
  flex-direction: row
  align-items: center
  .divider-content
    padding: 0 12px
  .divider
    height: 1px
    background-color: #cbcbcb
    flex: 1

.ant-modal-body
  padding-top: 10px

.modal-footer
  display: flex
  flex-direction: row
  justify-content: end
  position: sticky
  bottom: 0
  padding: 12px
  background-color: white

  button
    margin-left: 12px

</style>