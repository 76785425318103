<script setup>
import deDE from 'ant-design-vue/es/locale/de_DE';


</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Elagastro` : `Elagastro` }}</template>
  </metainfo>

  <a-config-provider :locale="deDE">
  <router-view v-slot="{ Component, route }" >
    <transition name="fade" mode="out-in">
      <div :key="route.name == null ? 'qq':route.name.split('_')[0]">
<!--        <pre>{{route}}</pre>-->
        <component :is="Component"></component>
      </div>
    </transition>
  </router-view>
  </a-config-provider>


</template>

<script>
import {ProductStore,CategoryStore,OrderStore,SlideStore} from "@/stores/crud_index";
import {
  FormModalStoreBrand,
  FormModalStoreHomeDesign,
  FormModalStoreProduct,
  FormModalStoreSlide, ModalStoreDiscount,ModalStoreNotification
} from "./stores/modal";
import {ConfigStore} from "./stores/config";
import {BrandStore, HomeDesignStore, UserStore} from "./stores/crud_index";
import {useMeta} from "vue-meta";

export default {
  name: 'App',

  mounted() {



    this.$store.productStore = ProductStore();
    this.$store.homeDesignStore = HomeDesignStore();
    this.$store.brandStore = BrandStore();
    this.$store.slideStore = SlideStore();
    this.$store.userStore = UserStore();
    this.$store.categoryStore = CategoryStore();
    this.$store.orderStore = OrderStore();
    this.$store.configStore = ConfigStore();
    this.$store.productFormStore = FormModalStoreProduct();
    this.$store.brandFormStore = FormModalStoreBrand();
    this.$store.homeDesignFormStore = FormModalStoreHomeDesign();
    this.$store.slideFormStore = FormModalStoreSlide();
    this.$store.modalStoreDiscount = ModalStoreDiscount();
    this.$store.modalStoreNotification = ModalStoreNotification();


  },

}
</script>

<style>

@import '@/assets/base.css';
@import '@/assets/bulma.css';


</style>
<style>
.columns:last-child{
  margin-bottom: 0px
}

.fade-enter-from {
  opacity: 0;
  transform: translateX(20px)
}

.fade-enter-active {
  transition: all 0.2s ease-out;
}

.fade-leave-to {
  opacity: 0;
  transform: translateX(-20px)
}

.fade-leave-active {
  transition: all 0.2s ease-in
}


</style>
