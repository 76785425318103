import { defineStore } from 'pinia'
const options = {

  state: () => ({
    showFormModal: false,
    data:{}
  }),

  actions: {
    toggle() {
      this.showFormModal = !this.showFormModal
    },
    openWithData(data){
      this.data = data
      this.showFormModal=true
    },
    openWithEmptyData(){
      this.data = {}
      this.showFormModal=true
    }
  }
}
export const FormModalStoreProduct = defineStore('FormModalStoreProduct',options)
export const FormModalStoreBrand = defineStore('FormModalStoreBrand',options)
export const FormModalStoreHomeDesign = defineStore('FormModalStoreHomeDesign',options)
export const FormModalStoreCategory = defineStore('FormModalStoreCategory',options)
export const FormModalStoreSlide = defineStore('FormModalStoreSlide',options)
export const ShowProductStore = defineStore('ShowProductStore',options)
export const FormModalStoreAppstore = defineStore('FormModalStoreAppstore',options)
export const ModalStoreGooglePlay = defineStore('ModalStoreGooglePlay',options)
export const ModalStoreDiscount = defineStore('ModalStoreDiscount',options)
export const ModalStoreNotification = defineStore('modalStoreNotification',options)
