import { createRouter, createWebHistory } from "vue-router";


import Login from "../views/Login.vue";

import SignupSteps from "../views/SignupSteps.vue";
import DashboardHome from "../views/Dashboard/Home.vue";
import DashboardOrders from "../views/Dashboard/Orders.vue";
import DashboardCategories from "../views/Dashboard/Categories.vue";
import DashboardProducts from "../views/Dashboard/Products.vue";
import DashboardUsers from "../views/Dashboard/Users.vue";
import DashboardBrands from "../views/Dashboard/Brands.vue";
import DashboardHomeDesign from "../views/Dashboard/HomeDesign.vue";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import ForgetPassword from "../views/ForgetPassword.vue";
import App from "../views/Dashboard/App.vue";
import Settings from "../views/Dashboard/Settings.vue";
import Slides from "../views/Dashboard/Slides.vue";
import DashboardDiscounts from "../views/Dashboard/Discounts.vue";


const Packages = () => import('../views/Upgrade/Packages.vue')
const Payment = () => import('../views/Upgrade/Payment.vue')
const PaymentFailed = () => import('../views/Upgrade/PaymentFailed.vue')
const PaymentSuccess = () => import('../views/Upgrade/PaymentSuccess.vue')
const Payments = () => import('../views/Dashboard/Payments.vue')
const Merchants = () => import('../views/Dashboard/Merchants.vue')
const AppRequests = () => import('../views/Dashboard/AppRequests.vue')
const Website = () => import('../views/Dashboard/Website.vue')
const CustomDomain = () => import('../views/Dashboard/CustomDomain.vue')
const DomainRequests = () => import('../views/Dashboard/DomainRequests.vue')
const Notifications = () => import('../views/Dashboard/Notifications.vue')


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/forget-password",
      name: "forgetPassword",
      component: ForgetPassword,

    },
    {
      path: "/packages",
      name: "packages",
      component: Packages
    },
    {
      path: "/payment",
      name: "payment",
      component: Payment
    },
    {
      path: "/payment-failed",
      name: "paymentFailed",
      component: PaymentFailed
    },
    {
      path: "/payment-success",
      name: "paymentSuccess",
      component: PaymentSuccess
    },

    {
      path: "/signup/steps",
      name: "signupSteps",
      component: SignupSteps,

    },
    {
      path: "/",
      component: Dashboard,
      name: 'dashboard',
      children:[
        {
          path: "",
          name: "dashboard_home",
          component:DashboardHome,


        },
        {
          path: "orders",
          name: "dashboard_orders",
          component: DashboardOrders,

        },
        {
          path: "notifications",
          name: "dashboard_notifications",
          component: Notifications,

        },
        {
          path: "users",
          name: "dashboard_users",
          component: DashboardUsers,
        },
        {
          path: "merchants",
          name: "dashboard_merchants",
          component: Merchants,
        },
        {
          path: "payments",
          name: "dashboard_payments",
          component: Payments,
        },
        {
          path: "products",
          name: "dashboard_products",
          component: DashboardProducts,
          title:'products'

        },
        {
          path: "discounts",
          name: "dashboard_discounts",
          component: DashboardDiscounts,
          title:'discounts'

        },

        {
          path: "/categories",
          name: "dashboard_categories",
          component: DashboardCategories,

        },
        {
          path: "/home-design",
          name: "dashboard_homeDesign",
          component: DashboardHomeDesign,

        },
        {
          path: "/brands",
          name: "dashboard_brands",
          component: DashboardBrands,

        },
        {
          path: "/app",
          name: "dashboard_app",
          component: App,

        },
        {
          path: "/settings",
          name: "dashboard_settings",
          component: Settings,

        },
        {
          path: "/slides",
          name: "dashboard_slides",
          component: Slides,
        },
        {
          path: "/app-requests",
          name: "dashboard_appRequests",
          component: AppRequests,
        },
        {
          path: "/domain-requests",
          name: "dashboard_domainRequests",
          component: DomainRequests,
        },
        {
          path: "/custom-domain",
          name: "dashboard_customDomain",
          component: CustomDomain,
        },
        {
          path: "/website",
          name: "dashboard_website",
          component: Website,
        },
      ]
    },

  ],
});



export default router;
