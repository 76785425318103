import { defineStore } from 'pinia'
import {BASE_URL, get} from "../helper/api";
import {FormModalStoreHomeDesign} from "./modal";
 function options (indexUrl,countUrl){
   return {
     state: () => ({
       items: [],
       result:null,
       indexUrl:indexUrl,
       loading:false,
       counts:{
         total:0,
         active:0,
         disable:0,
       }
     }),

     actions: {
       async getItems(params){
         this.loading = true
         this.result = await get(this.indexUrl,params)

         this.items = this.result.json.data
         this.loading = false



       },
       async getCounts(){

         let result = await get(this.indexUrl+'/counts')
         console.log(result.json)
         this.counts = result.json

       },
       searchText(){

         if(this.indexUrl === ""){
           console.log('boş')
           return null
         }
         console.log(this.indexUrl)


         return new URL(BASE_URL+this.indexUrl).searchParams.get('search')

       },
     }
   }



}
export const ProductStore = defineStore('productStore',options('product'))
export const BrandStore = defineStore('brandStore',options('brand'))
export const HomeDesignStore = defineStore('homeDesignStore',options('homecard'))
export const SlideStore = defineStore('slideStore',options('slide'))
export const CategoryStore = defineStore('categoryStore',options('category'))
export const OrderStore = defineStore('orderStore',options('order'))
export const UserStore = defineStore('userStore',options('users'))
export const TableStore = defineStore('tableStore',options('table'))
