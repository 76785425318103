<script setup>

import {FormModalStoreProduct} from "../../../stores/modal";
const formModalStore  = FormModalStoreProduct()
import {PlusOutlined} from "@ant-design/icons-vue";


</script>
<template>
  <div class="crud-header">

    <div class="crud-title">{{ $t(title) }}</div>

        <a-button class="mr-3" v-if="openDialog" @click="openDialog" type="primary" >
          <template #icon>
            <PlusOutlined/>
          </template>
        </a-button>
  </div>
</template>

<script>

import {PlusOutlined} from "@ant-design/icons-vue";

export default {
  components:{PlusOutlined},
  props: {
    title: String,
    openDialog:Function
  },



}


</script>

<style lang="sass">
.crud-header
  margin: 12px
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center




  .crud-title
    font-size: 20px
</style>