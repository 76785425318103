
<template>
  <div class="page-start">


  <div class="container">



    <a-steps progress-dot :current="configStore.signupStep">
      <a-step :title="$t('signup')" description="" />
      <a-step :title="$t('verify')" description="" />
      <a-step  :title="$t('details')" description="" />
    </a-steps>

    <Transition mode="out-in">
    <VerifyEmail  v-if="configStore.signupStep === 1"/>
    <UserInfo  v-else-if="configStore.signupStep === 2"/>
    </Transition>

  </div>
  </div>
</template>

<script>
import VerifyEmail from "@/components/signup_steps/verify_email.vue";
import UserInfo from "@/components/signup_steps/user_info.vue";
import {ConfigStore} from "../stores/config";
import {get} from "../helper/api";
import {useMeta} from "vue-meta";


export default {
  setup(){
    const configStore = ConfigStore();
    return {configStore}
  },
  components:{VerifyEmail,UserInfo},
  name: "SignupSteps",
  async mounted() {
    useMeta({
      title: this.$t('home')
    })
    let user = await get('user')
    console.log(user.json)

    if(!user.json.data.emailVerified){
      this.configStore.signupStep=1
    }else if(user.json.data.businessName == null || user.json.data.businessName===""){
      this.configStore.signupStep=2
    }else{
      console.log('dashboard')
      // localStorage.setItem('token',localStorage.getItem('tempToken'))
      // this.$router.push({name:'dashboard'})
    }
  }


}
</script>

<style lang="sass" scoped>
.ant-steps-item-title
  font-weight: 500

</style>