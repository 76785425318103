<template>
  <a-spin :spinning="loading" :delay="500">
    <div class="is-flex is-flex-direction-row is-flex-wrap-wrap">
      <a-card class="m-3 ml-5 mt-5" style="width: fit-content">

        <a-switch @change="togglePaymentMethods('order')" v-model:checked="order"/>
        <span class="ml-4">{{ $t('orderActive') }}</span>

      </a-card>
      <a-card class="m-3 ml-5 mt-5" style="width: fit-content">

        <a-switch @change="togglePaymentMethods('offer')" v-model:checked="offer"/>
        <span class="ml-4">{{ $t('offerActive') }}</span>

      </a-card>

      <a-card class="m-3 ml-5 mt-5" style="width: fit-content">

        <a-switch @change="togglePaymentMethods('prices')" v-model:checked="prices"/>
        <span class="ml-4">{{ $t('showPrices') }}</span>

      </a-card>

      <a-card class="m-3 ml-5 mt-5" style="width: fit-content">

        <a-switch @change="togglePaymentMethods('orderWithoutPayment')" v-model:checked="orderWithoutPayment"/>
        <span class="ml-4">{{ $t('orderWithoutPayment') }}</span>

      </a-card>
    </div>

    <div class="columns">


      <div class="column  is-6-desktop is-12">
        <a-card>
          <div>
            <div class="payment-option">
              <div class="left">
                <IconStripe style="height: 40px;width: 50px"/>
                <span class="text">Stripe</span>
              </div>
              <a-switch @change="togglePaymentMethods('stripe')" v-model:checked="stripe"/>


            </div>
            <!--                    Stripe form-->
            <a-form
                v-if="stripe"
                class="m-4"
                :model="formStateStripe"
                name="basic"
                layout="vertical"
                autocomplete="off"
                @finish="onFinish('stripe')"
            >
              <a-form-item
                  :label="$t('publishableKey')"
                  name="stripePublishableKey"
                  :rules="[{ required: true }]"
              >
                <a-input v-model:value="formStateStripe.stripePublishableKey"/>
              </a-form-item>

              <a-form-item
                  :label="$t('secretKey')"
                  name="stripeSecretKey"
                  :rules="[{ required: true }]"
              >
                <a-input v-model:value="formStateStripe.stripeSecretKey"/>
              </a-form-item>


              <div class="has-text-right">
                <a-button :loading="loadingFormStripe" type="primary" html-type="submit">
                  <check-outlined/>
                  {{ $t('save') }}
                </a-button>

              </div>
            </a-form>
            <!--                    Stripe form-->
          </div>


        </a-card>
      </div>
      <div class="column  is-6-desktop is-12">
        <a-card>
          <div>


            <div class="payment-option">
              <div class="left">
                <IconPaypal style="height: 40px;width: 50px"/>
                <span class="text">Paypal</span>
              </div>
              <a-switch @change="togglePaymentMethods('paypal')" v-model:checked="paypal"/>
            </div>
            <!--                    Paypal form-->


            <a-form
                v-if="paypal"
                class="m-4"
                :model="formStatePaypal"
                name="basic"
                layout="vertical"
                autocomplete="off"
                @finish="onFinish('paypal')"
            >
              <a-form-item
                  :label="$t('clientId')"
                  name="paypalClientId"
                  :rules="[{ required: true }]"
              >
                <a-input v-model:value="formStatePaypal.paypalClientId"/>
              </a-form-item>

              <a-form-item
                  :label="$t('clientSecret')"
                  name="paypalClientSecret"
                  :rules="[{ required: true }]"
              >
                <a-input v-model:value="formStatePaypal.paypalClientSecret"/>
              </a-form-item>


              <div class="has-text-right">
                <a-button :loading="loadingFormPaypal" type="primary" html-type="submit">
                  <check-outlined/>
                  {{ $t('save') }}
                </a-button>

              </div>
            </a-form>
          </div>
          <!--                    Paypal form-->
        </a-card>
      </div>


    </div>
    <div class="columns">
      <div class="column">
        <a-card>


          <div class="title is-6">{{$t('shippingCost')}}</div>
          <div class="mb-2">
            <a-checkbox v-model:checked="isFreeShipping">{{$t('freeShipping')}}</a-checkbox>
          </div>



          <a-form-item class="mb-2" v-if="!isFreeShipping" >
            <template #label>
              {{$t('freeShippingCostMinAmount')}}
              <a-tooltip class="pl-1">
                <template #title>
                  {{$t('freeShippingCostInfo')}}
                </template>
                <question-circle-outlined/>
              </a-tooltip>
            </template>

            <a-input-number v-model:value="freeShippingCostMinAmount"/>
            <span class="pl-2">{{ '  ' + (freeShippingCostMinAmount / 100).toFixed(2) + ' €' }}</span>

          </a-form-item>
          <a-form-item :label="$t('shippingCost')" v-if="!isFreeShipping" >
            <a-input-number v-model:value="shippingCost"/>
            <span class="pl-2">{{ '  ' + (shippingCost / 100).toFixed(2) + ' €' }}</span>
          </a-form-item>
          <a-form-item class="mt-5 mb-0">
            <a-button @click="saveShipping"  :loading="loadingShipping" type="primary">
              <check-outlined/>
              {{ $t('save') }}
            </a-button>
          </a-form-item>

        </a-card>
      </div>

    </div>

  </a-spin>
</template>

<script>
import {defineComponent, reactive, ref} from "vue";

import {CheckOutlined,QuestionCircleOutlined} from "@ant-design/icons-vue";
import IconCash from "@/components/icons/IconCash.vue";
import IconStripe from "@/components/icons/IconStripe.vue";
import IconPaypal from "@/components/icons/IconPaypal.vue";
import {displayErrorMessage, displaySuccessMessage} from "@/helper/messages";
import {post, get} from "@/helper/api";

const sleep = m => new Promise(r => setTimeout(r, m))

export default defineComponent({
  name: "order",
  components: {CheckOutlined, IconCash, IconStripe, IconPaypal,QuestionCircleOutlined},
  setup() {
    const formStatePaypal = reactive({

      paypalClientId: '',
      paypalClientSecret: ''
    });
    const formStateStripe = reactive({

      stripePublishableKey: '',
      stripeSecretKey: ''
    });
    let loading = ref(false)

    let loadingFormPaypal = ref(false)
    let loadingFormStripe = ref(false)
    let loadingShipping = ref(false)



    return {formStatePaypal, formStateStripe, loading, loadingFormPaypal, loadingFormStripe,loadingShipping}
  },
  data() {
    return {
      cash: false,
      stripe: false,
      paypal: false,
      order: false,
      offer: false,
      prices: false,
      orderWithoutPayment: false,
      shippingCost:0,
      freeShippingCostMinAmount:0,
      isFreeShipping:false,

    }
  },
  async mounted() {
    this.loading = true
    await sleep(500)
    let result = await get('config')
    let config = result.json.config

    this.formStateStripe.stripePublishableKey = config.stripePublishableKey
    this.formStateStripe.stripeSecretKey = config.stripeSecretKey
    this.formStatePaypal.paypalClientId = config.paypalClientId
    this.formStatePaypal.paypalClientSecret = config.paypalClientSecret
    this.paypal = config.isPaypalActive
    this.stripe = config.isStripeActive
    this.cash = config.isCashActive
    this.order = config.isOrderActive
    this.offer = config.isOfferActive
    this.prices = config.showPrices
    this.orderWithoutPayment = config.orderWithoutPayment

    this.shippingCost = config.shippingCost
    this.freeShippingCostMinAmount = config.freeShippingCostMinAmount
    if(this.shippingCost === 0){
      this.isFreeShipping = true
    }
    this.loading = false
  },
  methods: {
    async togglePaymentMethods(type) {
      let data = type === 'stripe' ? {'isStripeActive': this.stripe} : type === 'cash' ?
          {'isCashActive': this.cash} : type === 'order' ? {'isOrderActive': this.order} :type === 'orderWithoutPayment' ? {'orderWithoutPayment': this.orderWithoutPayment} :
              type === 'prices' ? {'showPrices': this.prices}:
              type === 'offer' ? {'isOfferActive': this.offer}:{'isPaypalActive': this.paypal}
      let result = await post("config", data)
      if (!result.ok) {
        displayErrorMessage()
        return;
      }
      if (!result.json.error) {
        displaySuccessMessage()

      }
    },
    async saveShipping() {
      this.loading = true
      let data = {}
      if(this.isFreeShipping){
        data['shippingCost'] = 0
      }else{
        data['shippingCost'] = this.shippingCost
      }
      data['freeShippingCostMinAmount'] = this.freeShippingCostMinAmount

      await post("config",data )
      displaySuccessMessage()
      this.loading = false
    },

    async onFinish(type) {

      if (type === 'stripe') {
        this.loadingFormStripe = true
      } else {
        this.loadingFormPaypal = true
      }
      await sleep(500)

      let result = await post("config", type === 'stripe' ? this.formStateStripe : this.formStatePaypal)

      if (!result.ok) {
        displayErrorMessage()
        if (type === 'stripe') {
          this.loadingFormStripe = false
        } else {
          this.loadingFormPaypal = false
        }
        return;
      }


      if (!result.json.error) {
        displaySuccessMessage()

      }
      if (type === 'stripe') {
        this.loadingFormStripe = false
      } else {
        this.loadingFormPaypal = false
      }


    }
  },

})
</script>

<style lang="sass" scoped>
.payment-option
  display: flex
  align-items: center
  flex-direction: row
  justify-content: space-between

  .left
    display: flex
    align-items: center
    flex-direction: row

  .text
    margin-left: 8px
    font-size: 16px
</style>