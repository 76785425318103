<template>
  <a-modal :footer="null" v-model:visible="$store.slideFormStore.showFormModal" >
    <a-form
        :model="formState"
        name="basic"
        layout="vertical"
        autocomplete="off"
        @finish="onFinish(formState)"
    >

      <a-form-item name="upload" :label="$t('image')+' (1580x588)'">
        <a-upload
            v-model:file-list="thumbnailState"
            :show-upload-list="false"
            @change="(uploaded)=>{

              if(uploaded.file.status==='done'){
                this.thumbnailLoading=false

              }
              if(uploaded.file.status!=='done'){
                this.thumbnailLoading=true
              }
              if(uploaded.file.response){formState.image = uploaded.file.response.fileName}
            }"
            name="logo"
            action="https://api.elagastro.de/media/upload"
            list-type="picture-card"
        >
          <LoadingOutlined v-if="thumbnailLoading"></LoadingOutlined>
          <div v-else>
            <img style="max-width: 102px;max-height:102px" v-if="formState.image"
                 :src="'https://cdn.bringsdelivery.de/'+formState.image" alt="avatar"/>
            <div v-else>
              <PlusOutlined></PlusOutlined>
              <div class="ant-upload-text">{{$t('upload')}}</div>
            </div>

          </div>


        </a-upload>
      </a-form-item>
      <a-form-item :label="$t('product')" >
        <a-select
            allow-clear
            v-model:value="formState.product"
            show-search
            style="width: 100%"
            label-in-value
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            :options="products"
            @search="searchProduct"
        ></a-select>
      </a-form-item>

      <a-form-item :label="$t('category')" >
        <a-select
            allow-clear
            v-model:value="formState.category"
            show-search
            style="width: 100%"
            label-in-value
            :filter-option="false"
            :not-found-content="fetchingCategories ? undefined : null"
            :options="categories"
            @search="searchCategory"
        ></a-select>
      </a-form-item>


      <a-button key="submit" type="primary" html-type="submit" :loading="loading">{{ $t('save') }}</a-button>


    </a-form>
  </a-modal>


</template>

<script>
import {defineComponent, reactive} from "vue";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {get, post} from "../../../helper/api";

import {displayErrorMessage, displaySuccessMessage} from "../../../helper/messages";
import {useForm} from "ant-design-vue/es/form";


import {UploadOutlined, InboxOutlined, PlusOutlined, LoadingOutlined} from '@ant-design/icons-vue';


export default defineComponent({


  components: {
    QuillEditor,
    UploadOutlined,
    InboxOutlined,
    PlusOutlined,
    LoadingOutlined,
  },


  setup() {

    const formState = reactive({

      image: null,
      category:null,
      product:null
    });

    const {resetFields} = useForm(formState)
    return {formState, resetFields}
  },

  data() {
    return {
      loading: false,
      closeModalAfterSave: true,
      clearDataAfterSave: true,
      thumbnailLoading: false,
      thumbnailState: null,
      quill: null,
      fetching:false,
      fetchingCategories:false,
      products:[],
      categories:[]
    }
  },
  created() {
    //console.log(this.$refs.quill)
  },
  mounted() {

    //console.log(this.$refs)

    this.$store.slideFormStore.$subscribe((mutation, state) => {

      if (state.showFormModal) {

        if (state.data) {
          console.log(state.data)

          this.formState.id = state.data.key
          this.formState.image = state.data.image
          if(state.data.product){
            this.formState.product = {label:state.data.product.title,value:state.data.product.id}
          }

          if(state.data.category){
            this.formState.category = {label:state.data.category.title,value:state.data.category.key}
          }



        }
      } else {

        this.resetFields()
      }


    })
  },

  methods: {
    async searchProduct(value){
      let products = await get('product',{'search':value})
      //console.log(products.json.data)
      this.products = products.json.data.map((product)=>{
        return {label:product.title,value:product.id}
      })
    },
    async searchCategory(value){
      let products = await get('category',{'search':value})

      this.categories = products.json.data.nonGrouped.map((product)=>{
        return {label:product.title,value:product.key}
      })
    },

    async onFinish(formState) {
      this.loading = true
      //console.log(formState)

      let data = {image:formState.image}

      if(formState.category){
        data['category'] = formState.category.value
      }else{
        data['category'] = null
      }

      data['product'] = formState.product ?? null
      data['id'] = formState.id


      let result = await post('slide', data)
      //console.log(result)
      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }
      console.log(result.json)
      if (result.json.status) {
        this.loading = false
        displaySuccessMessage()

        this.$store.slideStore.getItems()
        this.$store.slideFormStore.showFormModal = false


      }
    }
  }


})
</script>

<style lang="sass" scoped>
.ant-modal-body
  padding-top: 10px

.modal-footer
  display: flex
  flex-direction: row
  justify-content: end
  position: sticky
  bottom: 0
  padding: 12px
  background-color: white

  button
    margin-left: 12px

</style>