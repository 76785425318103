<template>

  <div class="columns">
    <div class="column is-12 is-4-desktop">
      <a-empty v-if="treeData.length === 0"/>
      <a-button v-if="treeData.length !== 0" class="mb-3" @click="reOrder=!reOrder" :type="reOrder ? 'primary':''">
        <template #icon>
          <column-height-outlined />
        </template>
        {{$t('reOrder')}}</a-button>

      <a-tree
          v-if="treeData.length>0"
          class="pb-2 pt-2"
          checkable
          :auto-expand-parent="autoExpandParent"
          v-model:expanded-keys="expandedKeys"
          v-model:selectedKeys="selectedKeys"
          v-model:checkedKeys="checkedKeys"
          @drop="onDrop"
          @expand="onExpand"
          @select="categorySelected"
          :draggable="reOrder"
          :tree-data="treeData">

        <template #title="{ title,categoryModel,icon }">

          <div class="category-item home-card-item is-fullwidth">
            <div v-if="categoryModel.thumbnail" class="category-image">
              <img :src="'https://cdn.bringsdelivery.de/'+categoryModel.thumbnail">
            </div>

            <span v-if="title.indexOf(searchValue) > -1">
              {{ title.substr(0, title.indexOf(searchValue)) }}
              <span style="color: #f50">{{ searchValue }}</span>
              {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
            </span>
            <span v-else>{{ title }}</span>
          </div>
        </template>
      </a-tree>



    </div>
    <div  class="column is-12 is-8-desktop m-auto ">
      <a-card class="mb-3">
      <Transition mode="out-in">
        <div>



        <div v-if="!selectedCategory.parent && !selectedCategory.key ">
         <div style="font-size: 16px">
           {{$t('addCategory')}}
         </div>
        </div>


        <div class="is-flex is-flex-direction-row is-flex-wrap-wrap" v-if="selectedCategory.key">
          <a-button  @click="addNewCategory()" class="button-with-icon m-2">
            <ion-icon name="add-outline"></ion-icon>
            {{$t('addMainCategory')}}
          </a-button>

          <a-button @click="addNewSubCategory()" class="button-with-icon m-2">
            <ion-icon style="margin-right: 8px" name="duplicate-outline"></ion-icon>

            {{$t('addSubCategory')}}
          </a-button>
          <a-button v-if="selectedCategory.key" @click="removeCategory()" class="button-with-icon m-2" danger>
            <ion-icon name="trash-outline"></ion-icon>

            {{$t('delete')}}
          </a-button>
        </div>

        <a-button v-else-if="selectedCategory.parent" type="text" @click.prevent class="button-with-icon m-2 pl-0">

          {{$t('reOrder')}}
          {{$t('addingSubCategory')}}: <strong class="ml-1">{{ selectedCategory.parent.title }}</strong>
        </a-button>
        </div>
      </Transition>
      </a-card>

<a-card>
  <CategoryForm/>
</a-card>



    </div>
  </div>


</template>

<script>


import SingleItem from "@/components/dashboard/crud/category/single.vue";
import CategoryForm from "@/components/dashboard/forms/category_form.vue"


import {LoadingOutlined,ColumnHeightOutlined} from "@ant-design/icons-vue";
import {ref} from "vue";
import {FormModalStoreCategory} from "../../../../stores/modal";
import {post} from "../../../../helper/api";
import {displaySuccessMessage} from "../../../../helper/messages";

const getParentKey = (key, tree) => {
  let parentKey;

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    //console.log(key)

    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }

  return parentKey;
};
export default {


  setup() {
    //console.log('setup')

    const formModalStore = FormModalStoreCategory()

    const loading = ref(false)


    return {loading, formModalStore}
  },

  components: {SingleItem, LoadingOutlined, CategoryForm,ColumnHeightOutlined},
  data() {
    return {
      treeData: [],
      treeDataNonGrouped: [],
      treeDataNew:[],
      selectedKeys: [],
      checkedKeys: [],
      expandedKeys: [],
      searchValue: "",
      selectedCategory: {},
      autoExpandParent:true,
      reOrder:false,
    }
  },
  watch: {
    treeDataNew(newV,oldV) {
      this.treeData = newV

      post('category/tree',{
        tree:newV
      })
    },

    searchValue(oldValue, newValue) {

      this.expandedKeys = this.treeDataNonGrouped.map(item => {
        //console.log(item)

        if (item.title.indexOf(oldValue) > -1) {



         return getParentKey(item.key, this.treeData);
        }

        return null;
      }).filter((item, i, self) => item && self.indexOf(item) === i);


    },
    async checkedKeys(newV,oldV){
      //console.log('old='+oldV)
      //console.log('new='+newV)
      await post('category/status',{
        ids: newV,

      })
      displaySuccessMessage()
    }
  },
  methods: {
    onExpand(keys) {
      this.expandedKeys = keys;
      this.autoExpandParent = false;
    },
    onDrop(info){
      //console.log(info);
      const dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
      //console.log(dropPosition)

      const loop = (data, key, callback) => {
        data.forEach((item, index) => {
          if (item.key === key) {
            return callback(item, index, data);
          }

          if (item.children) {
            return loop(item.children, key, callback);
          }
        });
      };
      //console.log(this.treeData)
      const data = [...this.treeData]; // Find dragObject
      //console.log(data)

      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);

        dragObj = item;
      });


      if (!info.dropToGap) {

        // Drop on the content
        loop(data, dropKey, item => {
          item.children = item.children || []; /// where to insert 示例添加到头部，可以是随意位置

          item.children.unshift(dragObj);
        });
      } else if ((info.node.children || []).length > 0 && // Has children
          info.node.expanded && // Is expanded
          dropPosition === 1 // On the bottom gap
      ) {

        loop(data, dropKey, item => {
          item.children = item.children || []; // where to insert 示例添加到头部，可以是随意位置

          item.children.unshift(dragObj);
        });
      } else {

        let ar = [];
        let i = 0;

        loop(data, dropKey, (_item, index, arr) => {
          ar = arr;
          i = index;
        });


        if (dropPosition === -1) {

          ar.splice(i, 0, dragObj);
        } else {

          ar.splice(i + 1, 0, dragObj);
        }

      }




      this.treeData = data;
      this.treeDataNew = data;


    },
    async removeCategory(){
      await post('category/remove',{id:this.selectedCategory.key})
      await this.$store.categoryStore.getItems()
      this.selectedCategory = {}

    },
    addNewCategory() {
      this.selectedCategory = {}
      this.formModalStore.data = {}
      this.formModalStore.showFormModal = true
    },
    addNewSubCategory() {
      this.formModalStore.data = {parent: this.selectedCategory.key}
      this.selectedCategory = {parent: this.selectedCategory}

      this.formModalStore.showFormModal = true
    },
    categorySelected(selected, info) {
      this.selectedCategory = info.node.categoryModel
      //console.log(this.selectedCategory)
      this.formModalStore.data = this.selectedCategory
      this.formModalStore.showFormModal = true
      // let indexArrays = selected[0].split('-')
      // console.log(indexArrays)
      // let times=1;
      // while(indexArrays.length>times){
      //   if(times === 1){
      //     this.selectedCategory = this.$store.categoryStore.items[indexArrays[times]]
      //     console.log(this.selectedCategory)
      //   }
      //
      //   times++
      // }


    },
    generateTreeData() {
      //console.log(this.$store.categoryStore.items.grouped)
      this.treeData = this.$store.categoryStore.items.grouped;
      this.treeDataNonGrouped = this.$store.categoryStore.items.nonGrouped;
      if(this.treeData){
        this.treeData.map((data)=>{
          if(data.categoryModel.active){
            this.checkedKeys.push(data.categoryModel.key)
          }
        })
      }

      // this.$store.categoryStore.items.nonGrouped.forEach(
      //     (category)=>{
      //       let children = []
      //       if(category.childs){
      //         category.childs.forEach(
      //             (child)=>{
      //               children.push({
      //                 key:category.key+'-'+child.key,
      //                 title:child.title,
      //                 draggable:true,
      //                 image:child.thumbnail
      //               })
      //             }
      //         )
      //       }
      //
      //
      //       this.treeData.push({
      //         key:category.key,
      //         title:category.title,
      //         draggable:true,
      //         image:category.thumbnail,
      //         children:children
      //       })
      //     }
      // )
    }
  },

  async mounted() {

    if (this.$store.categoryStore.items.length === 0) {
      this.loading = true
    }
    this.$store.categoryStore.$subscribe((mutation, state) => {


      this.generateTreeData()

    })

    //console.log('mount')

    await this.$store.categoryStore.getItems()

    //console.log(this.treeData)


    this.loading = false


  }


}
</script>


<style lang="sass">

.ant-tree-node-content-wrapper
  background-color: transparent !important
.ant-tree-switcher
  align-items: center
  display: flex

.add-category
  display: flex
  align-items: center
  border-radius: 5px

  &:hover
    background-color: gray

  span
    margin: 2px 5px 0 8px

.ant-tree .ant-tree-treenode
  align-items: center

.ant-tree-checkbox
  margin: 0 4px 0 0

.columns
  margin: 12px

.category-item
  display: flex
  flex-direction: row
  align-items: center
  margin-left: 4px
  min-width: 100px

  ion-icon

    width: 56px

  .category-image
    margin-right: 8px
    width: 50px
    height: 40px
    display: flex
    justify-content: center
    align-items: center
    padding: 5px

    img
      max-width: 100%
      max-height: 100%

</style>