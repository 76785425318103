<script setup>


import CrudIndex from '@/components/dashboard/crud/product/index.vue'
import CrudHeader from '@/components/dashboard/crud/header.vue'
import ProductForm from '@/components/dashboard/forms/product_form.vue'
import InfoCard from '@/components/dashboard/crud/info_card.vue'



</script>


<template>


  <ProductForm/>

  <CrudHeader title="products"  :openDialog="$store.productFormStore.openWithEmptyData"/>

  <div class="pl-5">
    <b>{{total}}</b>{{  ' '+ $t('results')}}
  </div>
  <CrudIndex/>

</template>


<script>
import {get} from "@/helper/api.js";
import {useMeta} from "vue-meta";

export default {

  data(){
    return {
      total:0,
      active:0,
      disable:0,
    }
  },
  async mounted() {
    useMeta({
      title: this.$t('products')
    })
    let result = await get('product/counts')
    this.total = result.json.total
    this.active = result.json.active
    this.disable = result.json.disable
  }

}
</script>
<style lang="sass">
.info-cards
  margin: 0 0 0 14px

</style>