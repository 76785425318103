<script setup>

import LineChart  from '@/components/dashboard/charts/line_chart.vue'
import PieChart  from '@/components/dashboard/charts/pie_chart.vue'
</script>


<template>
  <div v-if="loading" class="is-flex is-justify-content-center p-4">
    <loading-outlined style="font-size: 30px" />
  </div>



  <div v-else class="columns is-multiline p-3">
    <div class="column  is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5"><img style="padding: 5px" class="title-icon" src="/images/euro.svg">{{$t('revenue')}}</h2>
      <a-card v-if="stats" :body-style="{'padding':'8px'}">
        <LineChart id="revenueChart" price :data="stats.orderRevenue"/>
      </a-card>
    </div>
    <div class="column  is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5"><img class="title-icon" src="/images/order.svg">{{$t('orders')}}</h2>
      <a-card v-if="stats" :body-style="{'padding':'8px'}">
        <LineChart :price="false" id="ordersChart" :data="stats.orders"/>
      </a-card>
    </div>

    <div class="column  is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5"><PeoplesTwo style="padding-right: 5px;height:30px;display: flex;align-items: center;font-size:22px"/>{{$t('newCustomers')}}</h2>
      <a-card v-if="stats" :body-style="{'padding':'8px'}">
        <LineChart :price="false" id="clientsChart" :data="stats.clients"/>
      </a-card>
    </div>
    <div class="column  is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5"><img class="title-icon" src="/images/bag-latest.svg">{{$t('newOrders')}}</h2>
      <div style="height: 300px">
        <div style="height: 300px">
          <a-card v-if="stats"   :body-style="{'padding':'8px'}" >
            <a-empty v-if="stats.lastOrders.length === 0" />
            <div style="margin-top:4px;display: flex;flex-direction: row;justify-content: space-between"  v-for="order in stats.lastOrders">
              <span style="margin-right: 3px">{{parseDate(order.created)}}</span><span style="font-weight: 500">{{(order.amount/100).toFixed(2) + ' ' + $store.configStore.user.config.currency}}</span>
            </div>
          </a-card>

        </div>
      </div>
    </div>
    <div class="column is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5"><img class="title-icon" src="/images/fav-chart.svg">{{$t('bestSellingProducts')}}</h2>
      <div style="height: 300px">
        <a-card v-if="stats"  :body-style="{'padding':'8px'}" >
          <a-empty v-if="stats.bestSellingProducts.length === 0" />
          <div v-for="product in stats.bestSellingProducts">
            <span style="font-weight: 500;margin-right: 3px">{{product.sold}}</span><span>{{product.title}}</span>
          </div>
        </a-card>
      </div>
    </div>

    <div class="column  is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5"><img class="title-icon" src="/images/pos.svg">{{$t('paymentOptions')}}</h2>
      <div style="height: 250px">
        <a-card v-if="stats"  :body-style="{'padding':'8px'}" >
          <a-empty v-if="stats.ordersByPaymentType.length === 0" />
          <PieChart  v-if="stats.ordersByPaymentType.length !== 0" id="paymentsChart" :data="stats.ordersByPaymentType"/>
        </a-card>
      </div>
    </div>
  </div>


</template>

<script>
import {get} from "../../helper/api";
import {PeoplesTwo} from "@icon-park/vue-next";
import {LoadingOutlined} from "@ant-design/icons-vue";
import {useMeta} from "vue-meta";

export default {

  data() {
    return {
      items:[],
      activeKey: '0',
      stats:null,
      loading:true
    }
  },
  components:{PeoplesTwo,LoadingOutlined},
  beforeMount() {
    useMeta({
      title: this.$t('home')
    })
  },
  mounted() {

    this.getStats()
  },
  methods:{

    parseDate(dateString) {
      let date = new Date(dateString)
      return (date.getDate()).toString().padStart(2, "0") + '.' + (date.getMonth() + 1).toString().padStart(2, "0") + '.' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0")
    },
    async getStats(){
      this.stats = (await get('stats')).json
      console.log(this.stats)
      this.loading = false
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  display: flex
  flex-direction: row
  align-items: center
.title-icon
  padding: 3px
  margin-right: 5px
  height: 30px
  width: 30px
</style>