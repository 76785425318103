<template>


      <a-spin :spinning="loading" :delay="500">
        <a-card>
          <a-form
              :model="formState"
              name="basic"
              layout="vertical"
              autocomplete="off"
              @finish="onFinish(formState)"
              @finishFailed="onFinishFailed"
          >
            <a-form-item
                :label="$t('backgroundColor')"
                name="backgroundColor"

            >
              <a-popover  trigger="click">
                <template #content>
                  <ColorPicker
                    :colors-default="defaultColors"
                      :color="formState.backgroundColor"
                      theme="light"
                      :sucker-hide="true"
                      @changeColor="(color)=>{formState.backgroundColor=color.hex}"

                  />
                </template>

                <div class="picker-container" :style="'background-color:'+formState.backgroundColor"></div>
              </a-popover>
            </a-form-item>
            <a-form-item
                :label="$t('primaryColor')"
                name="primaryColor"

            >
              <a-popover  trigger="click">
                <template #content>
                  <ColorPicker
                      :color="formState.primaryColor"
                      theme="light"
                      :colors-default="defaultColors"
                      :sucker-hide="true"
                      @changeColor="(color)=>{formState.primaryColor=color.hex}"

                  />
                </template>

                <div class="picker-container" :style="'background-color:'+formState.primaryColor"></div>
              </a-popover>
            </a-form-item>
            <a-form-item
                :label="$t('textColor')"
                name="primaryColor"

            >
              <a-popover  trigger="click">
                <template #content>
                  <ColorPicker
                    :colors-default="defaultColors"
                      :color="formState.textColor"
                      theme="light"
                      :sucker-hide="true"
                      @changeColor="(color)=>{formState.textColor=color.hex}"

                  />
                </template>

                <div class="picker-container" :style="'background-color:'+formState.textColor"></div>
              </a-popover>
            </a-form-item>
            <a-form-item
                :label="$t('priceColor')"
                name="priceColor"

            >
              <a-popover  trigger="click">
                <template #content>
                  <ColorPicker
                    :colors-default="defaultColors"
                      :color="formState.priceColor"
                      theme="light"
                      :sucker-hide="true"
                      @changeColor="(color)=>{formState.priceColor=color.hex}"

                  />
                </template>

                <div class="picker-container" :style="'background-color:'+formState.priceColor"></div>
              </a-popover>
            </a-form-item>
            <a-form-item
                :label="$t('discountColor')"
                name="discountColor"

            >
              <a-popover  trigger="click">
                <template #content>
                  <ColorPicker
                    :colors-default="defaultColors"
                      :color="formState.discountColor"
                      theme="light"
                      :sucker-hide="true"
                      @changeColor="(color)=>{formState.discountColor=color.hex}"

                  />
                </template>

                <div class="picker-container" :style="'background-color:'+formState.discountColor"></div>
              </a-popover>
            </a-form-item>
            <a-form-item
                :label="$t('statusBarIconColorBlack')"
                name="statusBarIconBlack"

            >
              <a-switch @change="formState=formState" v-model:checked="formState.statusBarIconBlack"/>

            </a-form-item>

            <div class="is-flex is-justify-content-end">
              <a-button :loading="loading" type="primary" html-type="submit">
                <template #icon>
                  <CheckOutlined/>
                </template>
                {{ $t('save') }}
              </a-button>
            </div>




          </a-form>
        </a-card>
      </a-spin>

</template>

<script>
import {defineComponent, reactive} from "vue";

import currencyCodes from 'currency-codes'
import getSymbolFromCurrency from 'currency-symbol-map'
import {displayErrorMessage} from "@/helper/messages";

const sleep = m => new Promise(r => setTimeout(r, m))
import {ConfigStore} from "@/stores/config";
import {get, post} from "../../../helper/api";
import {CheckOutlined,PlusOutlined,LoadingOutlined} from "@ant-design/icons-vue";
import {displaySuccessMessage} from "../../../helper/messages";
import { ColorPicker } from 'vue-color-kit'
import 'vue-color-kit/dist/vue-color-kit.css'

export default defineComponent({
  metaInfo: {
    title: 'user_info_form'
  },
  components: {CheckOutlined,PlusOutlined,LoadingOutlined,ColorPicker},
  setup() {
    const configStore = ConfigStore();

    const formState = reactive({

      backgroundColor:'#ffffff',
      primaryColor:'#0a8f9c',
      textColor:'#111111',
      statusBarIconBlack:true
    });


    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      configStore,
      onFinishFailed,
      getSymbolFromCurrency
    };
  },
  mounted() {
    this.currencies = currencyCodes.codes()
    this.getUser()
  },
  data() {
    return {
      loading: false,
      currencies: [],
      thumbnailLoading: false,
      thumbnailState: null,
      defaultColors:[
        '#000000', '#FFFFFF',
        '#FF1900', '#F47365',
        '#FFB243', '#FFE623',
        '#439e4a', '#1BC7B1',
        '#00BEFF', '#2E81FF',
        '#5D61FF', '#FF89CF',
        '#FC3CAD', '#BF3DCE',
        '#8E00A7', 'rgba(0,0,0,0)']
    }
  },
  methods: {

    async getUser(){
      this.loading = true
      let user = await get('config')
      console.log(user)



      this.formState.statusBarIconBlack = user.json.config.statusBarIconBlack
      this.formState.backgroundColor = user.json.config.backgroundColor
      this.formState.primaryColor = user.json.config.primaryColor
      this.formState.textColor = user.json.config.textColor
      this.formState.priceColor = user.json.config.priceColor ?? user.json.config.primaryColor
      this.formState.discountColor = user.json.config.discountColor ?? user.json.config.primaryColor
      this.loading = false
    },
    async onFinish() {

      this.loading = true
      await sleep(500)
      let result = await post("config", this.formState)

      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }else{
        displaySuccessMessage()
        this.$store.configStore.iframeUpdate = !this.$store.configStore.iframeUpdate
      }
        this.loading = false
      }

    }
  }


);
</script>
<style lang="sass">
.hu-color-picker
  width: 220px !important
</style>

<style lang="sass" scoped>
.currency-select
  display: flex
  flex-direction: row
  .symbol
    width: 40px
    font-weight: bold
.hu-color-picker

  box-shadow: none
  .color-type
    display: none
.colors .item:nth-child(8n+1)
  margin-left: 8px
.picker-container
  width: 50px
  height: 50px
  border-radius: 8px
  border: 1px solid #e2e2e2
.ant-modal-body
  padding-top: 10px
</style>