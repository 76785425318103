<template>

  <link rel="stylesheet" href="/invoice/assets/css/style.css">
  <div v-if="order" id="bill" class="cs-container ">

    <div class="cs-invoice cs-style1" style="padding: 5px">
      <div class="cs-invoice_in" id="download_section">
        <div class="cs-invoice_head cs-type1 cs-mb25" style="align-items: center">
          <div class="cs-invoice_left">
            <p class="cs-invoice_number cs-primary_color cs-mb0 cs-f16"><b class="cs-primary_color">{{$t('order')}}:</b> #{{order.id}}</p>
          </div>
          <div class="cs-invoice_right cs-text_right">
            <div class="cs-logo cs-mb5">
              <img crossorigin="anonymous" style="height: 30px" :src="'https://cdn.appmee.de/'+$store.configStore.user.config.logo"  alt="Logo"></div>
          </div>
        </div>
        <div class="cs-invoice_head cs-mb10 column-mobile">
          <div class="cs-invoice_left">
<!--            <b class="cs-primary_color">Invoice / Recipt:</b>-->
            {{parseDate(order.created)}}<br>
            <p>{{$t('customer')}}: {{order.user.email}} <br>

            </p>

          </div>
          <div class="cs-invoice_right cs-text_right">
            <b class="cs-primary_color">{{$store.configStore.user.config.businessName}}</b>
            <p v-html="$store.configStore.user.address.replace(/\r?\n/g, '<br/>')">

            </p>
          </div>
        </div>
        <div class="cs-box2_wrap cs-mb30 column-mobile">
          <div class=" cs-box cs-style2">
            <b class="cs-primary_color">{{$t('address')}}</b><br>

            {{order.address.name + ' ' + order.address.lastname }}<br>
            {{order.address.phone}}<br>
            {{order.address.fullAddress}}<br>
            {{order.address.city + ' ' + order.address.postcode }}<br>
          </div>
          <div class=" cs-box cs-style2">
            <b class="cs-primary_color">{{$t('payment')}}</b><br>
            {{$t('status')}}: {{order.paid ? $t('paid'):$t('unpaid')}}<br>
            {{$t('paymentMethod')}}: {{order.paymentMethod }}
          </div>
        </div>

        <div class="cs-table cs-style2">
          <div class="cs-round_border">
            <div class="cs-table_responsive">
              <table style="width: 100%">
                <thead>
                <tr class="cs-focus_bg">
                  <th class="cs-width_6 cs-semi_bold cs-primary_color" style="text-align: start">{{$t('products')}}</th>
                  <th class="cs-width_2 cs-semi_bold cs-primary_color" style="text-align: end">{{$t('price')}}</th>
<!--                  <th class="cs-width_2 cs-semi_bold cs-primary_color"></th>-->
<!--                  <th class="cs-width_2 cs-semi_bold cs-primary_color cs-text_right">{{$t('total')}}</th>-->
                </tr>
                </thead>
                <tbody>
                <tr v-for="cartProduct in order.cartProducts">
                  <td class="cs-width_6">
                    <span style="font-weight: 600;margin-right: 8px">{{cartProduct.quantity}}x</span>{{cartProduct.product.title}}
                  </td>
                  <td class="cs-width_2 cs-text_right cs-primary_color" style="text-align: end">{{(cartProduct.price/100).toFixed(2) + ' ' + $store.configStore.user.config.currency}}</td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
          <div class="cs-invoice_footer">
            <div class="cs-left_footer cs-mobile_hide"></div>
            <div class="cs-right_footer">
              <table>
                <tbody>
                <tr class="cs-border_none">
                  <td class="cs-width_3 cs-border_top_0 cs-bold cs-f16 cs-primary_color">{{$t('total')}}</td>
                  <td class="cs-width_3 cs-border_top_0 cs-bold cs-f16 cs-primary_color cs-text_right" style="text-align: end">{{(order.amount/100).toFixed(2) + ' ' + $store.configStore.user.config.currency}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
<!--        <div class="cs-note">-->
<!--          <div class="cs-note_left">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>-->
<!--          </div>-->
<!--          <div class="cs-note_right">-->
<!--            <p class="cs-mb0"><b class="cs-primary_color cs-bold">Note:</b></p>-->
<!--            <p class="cs-m0">Here we can write a additional notes for the client to get a better understanding of this invoice.</p>-->
<!--          </div>-->
<!--        </div>&lt;!&ndash; .cs-note &ndash;&gt;-->
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "print_bill",
  props:{
    order:Object,
    printElement:null
  },
  methods:{
    parseDate(dateString) {
      let date = new Date(dateString)
      return (date.getDate()).toString().padStart(2, "0") + '.' + (date.getMonth() + 1).toString().padStart(2, "0") + '.' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0")

    },

  }

}
</script>

