<template>
  <discount_form :getItems="getItems" />
  <table-header title="discounts" :openDialog="$store.modalStoreDiscount.openWithEmptyData"/>
  <div class=" pt-1 pl-5 pr-5">
    <a-table

        :scroll="{ x: 'max-content' }"

        style="width: 100%"
        :columns="columns"
        :row-key="order => order.id"
        :data-source="items"
        :loading="loading"
        :pagination="pagination"
        @change="tableStateChanged"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'created'">
          {{parseDate(record.created)}}
        </template>


        <template v-if="column.dataIndex === 'percent'">
          <span>
              {{record.percent == null ? '-':(  '%' + record.percent )}}
          </span>
        </template>
        <template v-if="column.dataIndex === 'fixed'">
          <span>
              {{(record.fixed/100).toFixed(2) + ' ' + this.$store.configStore.user.config.currency }}
          </span>
        </template>

        <template v-if="column.dataIndex === ''">
          <a-button @click="$store.modalStoreDiscount.openWithData(record)" class="m-0" type="text">
            <template #icon>
              <edit-outlined/>
            </template>
          </a-button>
          <a-button :loading="loadingDelete" @click="deleteDiscount(record.id)"  type="text">
            <template #icon>
              <delete-outlined/>
            </template>
          </a-button>


        </template>


      </template>

    </a-table>
  </div>

</template>

<script>

import { get} from "@/helper/api";
import TableHeader from "../../components/dashboard/crud/header.vue";
import Discount_form from "../../components/dashboard/forms/discount_form.vue";
import {EditOutlined,DeleteOutlined} from "@ant-design/icons-vue";
import {post} from "../../helper/api";

export default {
  name: "Discounts",
  components: {Discount_form, TableHeader,EditOutlined,DeleteOutlined},
  mounted() {
    this.getItems()
  },
  data(){
    return {
      pagination: {
        pageSize: 20
      },
      items:[],
      loading:false,
      loadingDelete:false,

      tableState: {
        pagination: null, filters: null, sorter: null
      },
      columns:[
        {
          title: this.$t('date'),
          dataIndex: 'created',
          width: '150px',
        },
        {
          title: this.$t('title'),
          dataIndex: 'title'
        },
        {
          title: this.$t('percent'),
          dataIndex: 'percent'
        },
        {
          title: this.$t('fixed'),
          dataIndex: 'fixed'
        },
        {
          dataIndex: '',
          width: '100px',
        }
        ]
    }
  },
  methods:{
    parseDate(dateString) {
      let date = new Date(dateString)
      return (date.getDate()).toString().padStart(2, "0") + '.' + (date.getMonth() + 1).toString().padStart(2, "0") + '.' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0")

    },
    async deleteDiscount(id){
      this.loading = true
      await post('discount/delete',{
        'id':id
      })
      this.getItems()
      this.loading = false
    },
    tableStateChanged(pagination, filters, sorter) {
      console.log(pagination)
      console.log(pagination)
      let filter = {}
      if (pagination) {
        filter['page'] = pagination.current
      }
      if (filters) {
        if (filters['storeTable']) {
          filter['table'] = filters['storeTable']
        }
      }

      this.tableState = filter

      this.getItems()
    },
    async getItems() {
      this.loading = true

      let filter = this.tableState
      let result = await get('discount',filter)
      this.items = result.json.data
      this.pagination.total = result.json.totalItemCount
      this.loading = false

    }
  }
}
</script>

<style scoped>

</style>