<template>
  <a-modal width="1000px" :footer="null" v-model:visible="formModalStore.showFormModal">

    <a-form

        :model="formState"
        name="basic"
        layout="vertical"
        autocomplete="off"
        @finish="onFinish"
    >
      <a-form-item
          :label="$t('title')"
          name="title"
          :rules="[{ required: true}]"
      >
        <a-input v-model:value="formState.title"/>
      </a-form-item>

      <a-form-item
          :label="$t('category')"


      >
        <a-tree-select
            show-search
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            allow-clear
            tree-default-expand-all
            v-model:value="formState.category"

            :placeholder="$t('selectCategory')"
            :tree-data="$store.categoryStore.items.grouped"
        >

        </a-tree-select>
      </a-form-item>

      <a-form-item  :label="$t('brand')" >
        <a-select
            allow-clear
            v-model:value="formState.brand"
            show-search
            style="width: 100%"
            label-in-value
            :filter-option="false"
            :not-found-content="fetchingBrands ? undefined : null"
            :options="brands"
            @search="searchBrand"
        ></a-select>
      </a-form-item>


      <!--      <a-form-item-->
      <!--          label="Kısa Açıklama"-->
      <!--          name="shortDescription"-->
      <!--          :rules="[{ required: false }]"-->
      <!--      >-->
      <!--        <a-input v-model:value="formState.shortDescription"/>-->
      <!--      </a-form-item>-->

      <a-form-item
          :label="$t('description')"
          name="description"
          :rules="[{ required: false }]"
      >
        <QuillEditor  :modules="modules" toolbar="full" ref="quill" id="quill" contentType="html"
                     v-model:content="formState.description" theme="snow"/>
<!--        <ckeditor :config="editorConfig" :editor="editor" v-model="formState.description" ></ckeditor>-->

      </a-form-item>
      <a-form-item
          :label="$t('price')"
          name="price"
          :rules="[{ required: true }]"
      >
        <a-input-number v-model:value="formState.price"/>
        <span class="pl-2">{{ '  ' + (formState.price / 100).toFixed(2) + ' €' }}</span>
      </a-form-item>
      <a-form-item
          :label="$t('shippingCost')"
          name="shipping"
      >
        <div class="mb-2">
          <a-checkbox @change="specialShippingCostChanged"   v-model:checked="specialShippingCost">{{$t('specialShippingCost')}}</a-checkbox>
        </div>

        <a-input-number v-if="specialShippingCost" v-model:value="formState.shipping"/>
        <span v-if="specialShippingCost" class="pl-2">{{ '  ' + (formState.shipping / 100).toFixed(2) + ' €' }}</span>
      </a-form-item>
      <a-form-item :label="$t('specifyPricing')">
        <div style="position: relative" class="columns is-multiline m-0 p-0 variant-form"
             v-for="(pricing,index) in formState.customPrice">
          <div class="close-icon">
            <a-button @click="formState.customPrice.splice(index,1)" type="text">
              <template #icon>
                <CloseCircleOutlined/>
              </template>
            </a-button>
          </div>


          <div class="column is-4 p-0 pr-1">
            <a-form-item :label="$t('price')">
              <a-input-number v-model:value="pricing.price"/>
              <span class="pl-2">{{ '  ' + (pricing.price / 100).toFixed(2) + ' €' }}</span>
            </a-form-item>
          </div>
          <div class="column is-8 p-0 pl-1">
            <a-form-item :label="$t('user')">
              <a-select
                  allow-clear
                  v-model:value="pricing.user"
                  show-search
                  style="width: 100%"
                  label-in-value
                  :filter-option="false"
                  :not-found-content="fetchingUsers ? undefined : null"
                  :options="users"
                  @search="searchUser"
              ></a-select>
            </a-form-item>
          </div>
        </div>

        <a-button type="dashed" style="width: 100%" @click="addCustomPrice">
          <PlusOutlined/>
          {{$t('addSpecifyPricing')}}
        </a-button>
      </a-form-item>
      <a-form-item
          :label="$t('sale')"
          name="price"

      >
        <a-input-number v-model:value="formState.sale"/>
        <span v-if="formState.sale" class="pl-2">{{ '  ' + ( (formState.price - (formState.price * (formState.sale/100)) ) / 100).toFixed(2) + ' €' }}</span>

      </a-form-item>


      <a-form-item
          :label="$t('stock')"
          name="stock"

      >
        <a-input-number  v-model:value="formState.stock"/>
        <a-checkbox class="ml-1" v-model:checked="stockUnlimited">{{$t('unlimited')}}</a-checkbox>

      </a-form-item>

      <a-form-item :label="$t('productOptions')">
        <div style="position: relative" class="columns is-multiline m-0 p-0 variant-form"
             v-for="(variant,index) in formState.productVariantValues">
          <div class="close-icon">
            <a-button @click="formState.productVariantValues.splice(index,1)" type="text">
              <template #icon>
                <CloseCircleOutlined/>
              </template>
            </a-button>
          </div>


          <div class="column is-4 p-0 pr-1">
            <a-form-item :label="$t('title')">
              <a-input v-model:value="variant.productVariant.name"/>
            </a-form-item>
          </div>
          <div class="column is-8 p-0 pl-1">
            <a-form-item :label="$t('value')">
              <a-input v-model:value="variant.name"/>
            </a-form-item>
          </div>
          <div class="column is-4 p-0 m-0 ">
            <a-form-item :label="$t('extraPrice')">
              <a-input-number v-model:value="variant.price"/>
              <span class="pl-2">
                {{ '+  ' + ((variant.price ?? 0) / 100).toFixed(2) + ' €' }}
              </span>

            </a-form-item>

          </div>
          <div  class="column is-4 p-0 m-0 ">
            <a-form-item :label="$t('defaultSelected')">
              <a-checkbox v-model:checked="variant.defaultSelected"/>
            </a-form-item>
          </div>


        </div>

        <a-button type="dashed" style="width: 100%" @click="addVariant">
          <PlusOutlined/>
          {{$t('add')}}
        </a-button>
      </a-form-item>

      <a-form-item name="upload" :label="$t('image')">
        <a-upload
            v-model:file-list="thumbnailState"
            :show-upload-list="false"
            @change="(uploaded)=>{
              log(uploaded)
              if(uploaded.file.status==='done'){
                this.thumbnailLoading=false
                log(this.thumbnailState)
              }
              if(uploaded.file.status!=='done'){
                this.thumbnailLoading=true
              }
              if(uploaded.file.response){formState.thumbnail = uploaded.file.response.fileName}
            }"
            name="logo"
            action="https://api.elagastro.de/media/upload"
            list-type="picture-card"
        >
          <LoadingOutlined v-if="thumbnailLoading"></LoadingOutlined>
          <div v-else>
            <img v-if="formState.thumbnail"
                 :src="'https://cdn.bringsdelivery.de/'+formState.thumbnail" alt="avatar"/>
            <div v-else>
              <PlusOutlined></PlusOutlined>
              <div class="ant-upload-text">{{$t('upload')}}</div>
            </div>

          </div>


        </a-upload>
      </a-form-item>

      <a-form-item :label="$t('images')">
        <a-form-item name="dragger" no-style>
          <a-upload-dragger v-model:fileList="imagesState" :multiple="true" name="files"
                            list-type="picture"
                            action="https://api.elagastro.de/media/upload">
            <p class="ant-upload-drag-icon">
              <InboxOutlined/>
            </p>
            <p class="ant-upload-text">{{$t('uploadInfo')}}</p>
            <p class="ant-upload-hint">{{$t('uploadInfo2')}}</p>
          </a-upload-dragger>
        </a-form-item>
      </a-form-item>

      <div class="modal-footer">
        <a-button key="back" @click="formModalStore.toggle()">{{ $t('cancel') }}</a-button>

        <a-button key="submit" type="primary" html-type="submit" :loading="loading">{{ $t('save') }}</a-button>

      </div>


    </a-form>

  </a-modal>


</template>

<script>
import {defineComponent, reactive} from "vue";
import {FormModalStoreProduct} from "../../../stores/modal";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {get, post} from "../../../helper/api";
import ImageUploader from 'quill-image-uploader';
import {displayErrorMessage, displaySuccessMessage} from "../../../helper/messages";
import {useForm} from "ant-design-vue/es/form";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import {UploadOutlined, InboxOutlined, PlusOutlined, LoadingOutlined, CloseCircleOutlined} from '@ant-design/icons-vue';


export default defineComponent({
  name: "ProductForm",
  props: {
    product: null
  },
  components: {
    QuillEditor,
    UploadOutlined,
    InboxOutlined,
    PlusOutlined,
    LoadingOutlined,
    CloseCircleOutlined,
  },


  setup() {
    const formModalStore = FormModalStoreProduct()
    const formState = reactive({
      title: '',
      shortDescription: '',
      description: '',
      category: null,
      brand: null,
      price: 0,
      shipping: 0,
      stock: -1,
      thumbnail: null,
      images: [],
      productVariantValues: [],
      customPrice: [],

    });

    const modules = {
      name: 'imageUploader',
      module: ImageUploader,
      options: {
        upload: file => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file);

            fetch('https://api.elagastro.de/media/upload', {
              method: 'POST',
              body: formData
            }).then(async res => {
              let json = await res.json();
              resolve('https://cdn.bringsdelivery.de/' + json.fileName)
            })
                .catch(err => {
                  reject("Upload failed");
                  console.error("Error:", err)
                })
          })
        }
      }
    }

    const {resetFields} = useForm(formState)
    return {formState, formModalStore, resetFields, modules}
  },
  data() {
    return {
      stockUnlimited:true,
      loading: false,
      closeModalAfterSave: true,
      clearDataAfterSave: true,
      thumbnailLoading: false,
      specialShippingCost: false,
      thumbnailState: null,
      quill: null,
      imagesState: null,
      brands:[],
      users:[],
      fetchingBrands:false,
      fetchingUsers:false,
      // editor: ClassicEditor,
      editorConfig: {

        ckfinder: {
          uploadUrl: 'https://api.elagastro.de/media/upload'
        }}
    }
  },
  created() {
    console.log(this.$refs.quill)
  },

  mounted() {

    this.getCategories()

    this.formModalStore.$subscribe((mutation, state) => {

      if (state.showFormModal) {
        if (state.data) {
          console.log(state.data)

            this.stockUnlimited=state.data.stock===-1


          this.formState.id = state.data.id
          this.formState.title = state.data.title ?? ""
          this.formState.stock = state.data.stock
          this.formState.sale = state.data.sale
          this.formState.shipping = state.data.shipping
          if(state.data.shipping !== -1){
            this.specialShippingCost = true
          }
          this.formState.shortDescription = state.data.shortDescription ?? ""
          this.formState.description = state.data.description ?? ""
          this.formState.productVariantValues = state.data.productVariantValues ?? []
          if(state.data.customPrice){
            this.formState.customPrice = state.data.customPrice.map((c)=>{
              return {price:c.price,user:{label:c.user.email + ' - ' + c.user.name+ ' ' + c.user.lastname,value:c.user.id}}
            })
          }

          if (state.data.category) {
            console.log('evet')
            this.formState.category = {label:state.data.category.title,value:state.data.category.key}
          }
          if (state.data.brand) {
            this.formState.brand = {label:state.data.brand.name,value:state.data.brand.id}
          }


          this.$nextTick(() => {
            let self = this
            self.$refs.quill.setHTML(state.data.description ?? '')
          });


          this.formState.price = state.data.price ?? 0
          if (state.data.thumbnail) {
            this.formState.thumbnail = state.data.thumbnail
          }
          if (state.data.images && state.data.images.length > 0) {
            this.formState.thumbnail = state.data.thumbnail

            this.imagesState = state.data.images.map((imageName) => {
              return {
                uid: imageName,
                name: imageName,
                status: 'done',
                thumbUrl: 'https://cdn.bringsdelivery.de/' + imageName,
              }
            })

          }
        }
      } else {
        this.resetFields()
      }


    })
  },

  methods: {
    specialShippingCostChanged(){
      if(this.specialShippingCost && (this.formState.shipping===-1 || this.formState.shipping===0)){
        this.formState.shipping = 100
      }
    },
    async searchBrand(value){
      let products = await get('brand',{'search':value})

      this.brands = products.json.data.map((product)=>{
        return {label:product.name,value:product.id}
      })
    },
    async searchUser(value){
      let products = await get('users',{'search':value})

      this.users = products.json.data.map((user)=>{
        return {label:user.email + ' - ' + user.name+ ' ' + user.lastname,value:user.id}
      })
    },
    async searchVariant(value) {

      this.variants = [{label: value, value: value}]


      console.log(this.variants)
    },
    addVariant() {
      this.formState.productVariantValues.push({productVariant: {name: ''}, name: "", price: 0,defaultSelected:false})
    },
    addCustomPrice() {
      this.formState.customPrice.push({price: 0})
    },
    log(item) {
      console.log(item)
    },

    async getCategories() {

      if (this.$store.categoryStore.items.length < 1) {
        await this.$store.categoryStore.getItems()
      }


    },
    test() {
      console.log(this.$refs.quill)
    },
    async onFinish() {
      this.loading = true
      if(!this.specialShippingCost){
        this.formState.shipping = -1
      }
      if (this.imagesState) {
        this.formState.images = this.imagesState.map((image) => {
          return image.response == null ? image.name : image.response.fileName
        })
      }

      if(this.stockUnlimited){
        this.formState.stock=-1
      }

      if(this.formState.sale == null){
        this.formState.sale = 0
      }


      let result = await post('product', this.formState)

      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }
      console.log(result.json)
      if (result.json.status) {
        this.loading = false
        displaySuccessMessage()

        this.$store.productStore.getItems()


          this.resetFields()



          this.formModalStore.showFormModal = false


      }
    }
  }


})
</script>
<style lang="sass">
.modal-footer
  z-index: 1000000
.ql-editor
  min-height: 150px
</style>

<style lang="sass" scoped>
.close-icon
  position: absolute
  right: 0
  top: 0
  z-index: 1000

.variant-form
  border: 1px solid #e2e2e2
  padding: 8px !important
  border-radius: 5px
  margin-bottom: 8px !important

  .ant-form-item

    margin-bottom: 8px

.ant-modal-body
  padding-top: 10px

.modal-footer
  display: flex
  flex-direction: row
  justify-content: end
  position: sticky
  bottom: 0
  padding: 12px
  background-color: white

  button
    margin-left: 12px

</style>