<template>
  <a-popover trigger="click">
    <template #content>
      <a-form style="width: 200px" layout="vertical">
        <a-form-item :label="$t('trackingNumber')" >
          <a-input v-model:value="offer"></a-input>
        </a-form-item>
        <a-button type="primary" @click="updateOffer" :loading="loading">
          <template #icon>
            <check-outlined/>
          </template>
          {{$t('save')}}
        </a-button>
      </a-form>
    </template>
    <a-button size="small" >
      {{$t('trackingNumber')}}
      <template #icon>
        <InboxOutlined/>

      </template>
    </a-button>
  </a-popover>
</template>

<script>
import {post} from "@/helper/api";
import {CheckOutlined,PercentageOutlined,InboxOutlined} from "@ant-design/icons-vue";

export default {
  name: "update_tracking_number",
  components:{CheckOutlined,PercentageOutlined,InboxOutlined},
  props:{
    order:Object,
    getItems:Function
  },
  data(){
    return {
      loading:false,
      offer:this.order.offer
    }

  },
  methods:{
    async updateOffer(){
      this.loading = true
      await post('order/trackingNumber',{
        id:this.order.id,
        number:this.offer
      })
      this.loading=false
      this.getItems()

    }
  }
}
</script>

<style scoped>

</style>