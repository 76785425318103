<script setup>


import CrudIndex from '@/components/dashboard/crud/category/index.vue'



</script>
<script>




import {useMeta} from "vue-meta";

export default {

  async mounted() {
    useMeta({
      title: this.$t('categories')
    })
    this.$store.categoryStore.getCounts()

  }
}
</script>


<template>


  <div style="margin:12px">
    <div class="crud-title">{{ $t('categories') }}</div>
  </div>



  <CrudIndex  />

</template>



<style lang="sass">



.crud-title
  font-size: 20px

</style>