<template>
  <CrudHeader :title="$t('slides')"  :openDialog="$store.slideFormStore.openWithEmptyData"/>
  <Slide_form/>
  <a-empty v-if="!$store.slideStore.loading && $store.slideStore.items.length === 0"/>
  <div v-if="!$store.slideStore.loading && $store.slideStore.items.length !== 0" class="top-buttons">
    <a-button class="mr-3" @click="reOrder=!reOrder" :type="reOrder ? 'primary':''">
      <template #icon>
        <column-height-outlined/>
      </template>
      {{ $t('reOrder') }}
    </a-button>
  </div>

  <div class="home-design columns is-multiline">

      <a-spin :spinning="$store.slideStore.loading">




    <a-tree
        v-if="treeData.length>0"

        @drop="onDrop"
        blockNode
        :draggable="reOrder"
        :tree-data="treeData">
      <template #title="{ title,subtitle,image,products,key,data }">



        <div class="slide-card">
          <div class="image">
            <img :src="'https://cdn.bringsdelivery.de/'+image">

          </div>

          <div class="actions">
            <a-button @click="$store.slideFormStore.openWithData(data)" type="text">
              <template #icon>
                <FormOutlined/>
              </template>
            </a-button>

            <a-button @click="deleteSlide(data.key)" type="text" danger>
              <template #icon>
                <DeleteOutlined/>

              </template>
            </a-button>

          </div>
        </div>

      </template>
    </a-tree>

      </a-spin>
<!--    <div v-show="!$store.slideStore.loading" v-for="slide in $store.slideStore.items" class="column is-3">-->
<!--     -->

<!--    </div>-->


  </div>

</template>

<script>

import {MailOutlined,FileDoneOutlined,LoadingOutlined,PlusOutlined,DeleteOutlined,FormOutlined,ColumnHeightOutlined} from "@ant-design/icons-vue";

import Settings_form from "../../components/dashboard/forms/settings_form.vue";
import CrudHeader from '@/components/dashboard/crud/header.vue'


import Slide_form from "../../components/dashboard/forms/slide_form.vue";
import {post} from "../../helper/api";
import {useMeta} from "vue-meta";
export default {
  name: "Settings",
  components: {
    Slide_form, Settings_form,MailOutlined,FileDoneOutlined,LoadingOutlined,PlusOutlined,CrudHeader,DeleteOutlined,FormOutlined,ColumnHeightOutlined},
  data(){
    return {

      thumbnailLoading: false,
      thumbnailState: null,
      treeData: [],
      treeDataNew: [],
      reOrder: false,
    }
  },
  async mounted() {
    useMeta({
      title: this.$t('slides')
    })
    await this.$store.slideStore.getItems()
    this.$store.slideStore.$subscribe((a,b)=>{
      this.generateTreeData()
    })
    this.generateTreeData()
  },
  methods:{
    async deleteSlide(id){
      await post('slide/delete',{"id":id})
      await this.$store.slideStore.getItems()
      this.generateTreeData()

    },
    onDrop(info) {

      const dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);


      const loop = (data, key, callback) => {
        data.forEach((item, index) => {
          if (item.key === key) {
            return callback(item, index, data);
          }

          if (item.children) {
            return loop(item.children, key, callback);
          }
        });
      };

      const data = [...this.treeData]; // Find dragObject


      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);

        dragObj = item;
      });


      if (!info.dropToGap) {

        // Drop on the content
        loop(data, dropKey, item => {
          item.children = item.children || []; /// where to insert 示例添加到头部，可以是随意位置

          item.children.unshift(dragObj);
        });
      } else if ((info.node.children || []).length > 0 && // Has children
          info.node.expanded && // Is expanded
          dropPosition === 1 // On the bottom gap
      ) {

        loop(data, dropKey, item => {
          item.children = item.children || []; // where to insert 示例添加到头部，可以是随意位置

          item.children.unshift(dragObj);
        });
      } else {

        let ar = [];
        let i = 0;

        loop(data, dropKey, (_item, index, arr) => {
          ar = arr;
          i = index;
        });


        if (dropPosition === -1) {

          ar.splice(i, 0, dragObj);
        } else {

          ar.splice(i + 1, 0, dragObj);
        }

      }


      this.treeData = data;
      this.treeDataNew = data;



    },
    generateTreeData() {

      this.treeData = this.$store.slideStore.items;



    },

  },
  watch: {
    treeDataNew(newV, oldV) {
      this.treeData = newV


      post('slide/sequence', {
        tree: newV
      })
    },
  }

}
</script>

<style lang="sass" scoped>
.slide-card
  border-radius: 8px
  background-color: white
  width: 100%
  padding: 8px
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 12px
  .actions
    margin-top: 8px
  .image
    height: 150px
    display: flex
    justify-content: center
    align-items: center
    img
      max-height: 100%

  img
    height: 100%
    width: auto
</style>