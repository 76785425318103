import {message} from "ant-design-vue";
import {ConfigStore} from "@/stores/config";
export function displaySuccessMessage(msg){
    let configStore = ConfigStore()
    message.success({
        content: () => msg ?? configStore.successMessage,

    })
}
export function displayErrorMessage(msg){
    let configStore = ConfigStore()
    message.error({
        content: () => msg ?? configStore.errorMessage
    })
}