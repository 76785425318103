<template>
  <div class="social-icons">

    <GoogleLogin :callback="authGoogle" popup-type="TOKEN">
      <div class="button-box">
        <loading-outlined style="font-size: 30px;padding:8px" v-if="googleLoading"/>
        <img v-else src="/images/google.svg">
        <div>
          {{ $t('googleIleDevam') }}
        </div>
      </div>
    </GoogleLogin>


    <div @click="logInWithFacebook" class="button-box">

      <loading-outlined style="font-size: 30px;padding:8px" v-if="facebookLoading"/>
      <img v-else src="/images/facebook.svg">
      <div>

        <div>
          {{ $t('facebookIleDevam') }}
        </div>
      </div>

      <!--        <div class="button-box">-->
      <!--          <img src="/images/apple.svg">-->
      <!--          <div>-->
      <!--            {{$t('appleIleDevam')}}-->
      <!--          </div>-->
      <!--        </div>-->


    </div>
  </div>
</template>

<script>
import {post} from "../../helper/api";

export default {
  name: "social_login_buttons",
  async mounted() {
    await this.loadFacebookSDK(document, "script", "facebook-jssdk");
    await this.initFacebook();
  },
  data() {
    return {
      loading: false,
      googleLoading: false,
      facebookLoading: false,
    }
  },
  methods:{

    async authGoogle(response) {
      this.googleLoading = true
      let result = await post('login/google', {
        token: response.access_token
      })
      this.googleLoading = false


      localStorage.setItem('token', result.json.token)
      if(result.json.newUser){
        this.$router.push({name: 'signupSteps'})
      }else{
        this.$router.push({name: 'dashboard_home'})
      }
    },
    async authFacebook(response) {
      if (response.authResponse) {
        this.facebookLoading = true
        let result = await post('login/facebook', {
          token: response.authResponse.accessToken
        })
        if(result.json.token){
          this.facebookLoading = false


          localStorage.setItem('token', result.json.token)
          if(result.json.newUser){
            this.$router.push({name: 'signupSteps'})
          }else{
            this.$router.push({name: 'dashboard_home'})
          }
        }

      }
    },
    async logInWithFacebook() {


      window.FB.login(this.authFacebook);
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "1720425381687269", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v13.0"
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
          fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }
  }
}
</script>

<style scoped>

</style>