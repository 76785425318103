<template>




</template>

<script>
export default {
  name: "print_bill",
  props:{
    order:Object,
    printElement:null
  },

}
</script>

<style scoped>

</style>