<template>
  <div class="columns mb-4">
    <div class="column is-12-tablet is-6-desktop is-4-fullhd">
      <a-spin :spinning="loading" :delay="500">
        <a-card>
          <a-form
              :model="formState"
              name="basic"
              layout="vertical"
              autocomplete="off"
              @finish="onFinish(formState)"
              @finishFailed="onFinishFailed"
          >


            <a-form-item
                :label="$t('name')"
                name="name"
            >
              <a-input v-model:value="formState.name"/>
            </a-form-item>
            <a-form-item
                :label="$t('lastname')"
                name="lastname"
            >
              <a-input v-model:value="formState.lastname"/>
            </a-form-item>
            <a-form-item
                :label="$t('email')"
                name="emailContact"
            >
              <a-input v-model:value="formState.emailContact"/>
            </a-form-item>

            <a-form-item
                :label="$t('phone')"
                name="phone"


            >
              <a-input  v-model:value="formState.phone"/>
            </a-form-item>

            <a-form-item
                :label="$t('address')"
                name="address"


            >
              <a-textarea  v-model:value="formState.address"/>
            </a-form-item>

            <div class="is-flex is-justify-content-end">
              <a-button :loading="loading" type="primary" html-type="submit">
                <template #icon>
                  <CheckOutlined/>
                </template>
                {{ $t('save') }}
              </a-button>
            </div>




          </a-form>
        </a-card>
      </a-spin>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "vue";

import currencyCodes from 'currency-codes'
import getSymbolFromCurrency from 'currency-symbol-map'
import {displayErrorMessage} from "@/helper/messages";

const sleep = m => new Promise(r => setTimeout(r, m))
import {ConfigStore} from "@/stores/config";
import {get, post} from "../../../helper/api";
import {CheckOutlined} from "@ant-design/icons-vue";
import {displaySuccessMessage} from "../../../helper/messages";


export default defineComponent({
  metaInfo: {
    title: 'user_info_form'
  },
  components: {CheckOutlined},
  setup() {
    const configStore = ConfigStore();

    const formState = reactive({
      emailContact: '',
      phone:""
    });


    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      configStore,
      onFinishFailed,
      getSymbolFromCurrency
    };
  },
  mounted() {
    this.currencies = currencyCodes.codes()
    this.getUser()
  },
  data() {
    return {
      loading: false,
      currencies: [],

    }
  },
  methods: {

    async getUser(){
      let user = await get('user')
      this.$store.configStore.user = user.json.data
      this.formState.emailContact = user.json.data.emailContact
      this.formState.phone = user.json.data.phone
      this.formState.name = user.json.data.name
      this.formState.lastname = user.json.data.lastname
      this.formState.address = user.json.data.address
    },
    async onFinish() {

      this.loading = true
      await sleep(500)
      let result = await post("user", this.formState)

      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }else{
        displaySuccessMessage()
      }
        this.loading = false
      }

    }
  }


);
</script>

<style lang="sass" scoped>
.currency-select
  display: flex
  flex-direction: row
  .symbol
    width: 40px
    font-weight: bold
</style>