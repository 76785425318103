
<template>
  <div>
    <VueApexCharts


        :options="chartOptions"
        :series="series"

    ></VueApexCharts>
  </div>

</template>

<script>



import VueApexCharts from "vue3-apexcharts";
export default {
  name: "LineChart",
  props: {
    id: String,
    data:Array,
    price:Boolean
  },
  components:{
    VueApexCharts
  },


  async beforeMount() {

    //console.log(this.data)

    let dates = []
    let datesWithYear = []
    let chartData=[]
    for (let k = 7; k > 0; k--) {
      let date = new Date(new Date().setDate(new Date().getDate() - k))

      let formatted = date.getFullYear()+'-'+(date.getMonth() + 1).toString().padStart(2, "0")+ '-' + (date.getDate() + 1).toString().padStart(2, "0")
      let founded=[]
      if(this.data){
        founded = this.data.filter((d)=>formatted === d.dates)
      }


      if(founded.length>0){
        // has data find count
        chartData.push(founded[0].count)
      }else{
        // no data add zero
        chartData.push(0)

      }


      datesWithYear.push(date.getFullYear()+'-'+(date.getMonth() + 1).toString().padStart(2, "0")+'-'+(date.getDate() + 1).toString().padStart(2, "0") )
      dates.push((date.getDate() + 1).toString().padStart(2, "0") + '/' + (date.getMonth() + 1).toString().padStart(2, "0"))
    }
    if(this.price){
      chartData = chartData.map((d)=>(d/100).toFixed(0))
      this.chartOptions.tooltip.custom = function({series, seriesIndex, dataPointIndex, w}) {
        return '<div class="pl-4 pr-4 p-2">' +
            '<span>' + series[seriesIndex][dataPointIndex] + ' ' +currency+ '</span>' +
            '</div>'
      }
      this.chartOptions.yaxis = {
        labels: {
          formatter: function (val) {
            return val+' '+currency;
          },
        },

      }
    }

    //console.log(chartData)


    let currency = this.$store.configStore.user.config.currency
    this.chartOptions.xaxis.categories = dates



    //console.log(this.id)
    await this.$store.configStore.getCurrentUser()






    this.series = [{
      title:this.$t('revenue'),
      data:chartData
    }]


  },
  data() {
    return {
      chartOptions:{

        colors:['#039e0d', '#ade3a466', '#039e0d'],
        stroke: {
          width:2
        },
        tooltip:{

        },
        dataLabels: {
          enabled: false
        },

        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },

        chart: {
          type: 'area',
          toolbar: {
            show: false}
        },
        xaxis: {
          categories: [],
          tooltip: {
            enabled: false
          }
        },
      },
      series: [

      ],
    }
  }

}


</script>

<style scoped>

</style>