
<script setup >
import Navbar from '@/components/navbar.vue'
import Form from '@/components/dashboard/forms/forget_password_form.vue'
</script>
<template>
  <Navbar />
  <div class="page-start">
    <div class="container is-fullhd">
      <div class="login">


      <div class="columns">
        <div class="box login-box column is-6 is-4-fullhd ">
          <h1 class="title is-3 has-text-centered mb-4">{{$t('forgetPassword')}}</h1>

          <Form />
        </div>
      </div>
      </div>
    </div>
  </div>


</template>
<script>
import {useMeta} from "vue-meta";

export default {
 mounted() {
   useMeta({
     title: this.$t('forgetPassword')
   })
 }
}
</script>


