<template>
  <svg  height="1em" width="1em"  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       viewBox="0 0 502.664 502.664" >
<g>
	<g>
		<g >
			<polygon  points="83.155,234.108 102.504,306.111 130.978,306.111 166.979,194.202 137.708,194.202
				116.353,267.542 97.629,194.202 69.048,194.202 49.742,267.542 28.775,194.202 0,194.202 35.419,306.111 64.238,306.111 			"/>
      <polygon  points="265.472,194.202 236.912,194.202 217.584,267.542 196.531,194.202 167.756,194.202
				203.24,306.111 232.08,306.111 251.019,234.108 270.411,306.111 298.842,306.111 334.843,194.202 305.636,194.202
				284.173,267.542 			"/>
      <polygon  points="473.392,194.202 451.973,267.542 433.292,194.202 404.668,194.202 385.34,267.542
				364.395,194.202 335.577,194.202 371.082,306.111 399.879,306.111 418.818,234.108 438.146,306.111 466.662,306.111
				502.664,194.202 			"/>
      <path  d="M251.321,35.764c-86.564,0-161.026,51.166-195.28,124.765h390.582
				C412.326,86.93,337.885,35.764,251.321,35.764z"/>
      <path  d="M251.321,466.9c86.585,0,160.961-51.144,195.28-124.765H56.084
				C90.338,415.734,164.758,466.9,251.321,466.9z"/>
		</g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "IconWWW"
}
</script>

<style scoped>

</style>