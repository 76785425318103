import { createI18n } from 'vue-i18n'



export default createI18n({
  legacy: false,
  locale: localStorage.getItem('locale') || 'de',
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  globalInjection: true,
  messages: {
    de: {
        homeDesign:"Hauptseite",
        merchants:" Verkäufer ",
        registerDate:"Registrierungsdatum",
        package:"Paket",
        merchant:"Verkäufer",
        slides:"Slides",
        packages:"Pakete",
        backToPackages:"Zurück zu Seiten",
        payment:"Zahlung",
        paymentFailed:"Zahlung fehlgeschlagen",
        paymentSuccess:"Zahlung erfolgreich",
        tryAgain:"Erneut probieren",
        backToHome:"Zurück zu Startseite",
        paymentSuccessfullyCompleted:"Zahlung erfolgreich durchgeführt",
        addCategory:"Kategorie hinzufügen",
        addMainCategory:"Hauptkategorie hinzufügen",
        addSubCategory:"Unterkategorie hinzufügen",
        addingSubCategory:" Unterkategorie für Kategorie hinzufügen",
        orderStatus:"Bestellstatus",
        user:"Benutzer",
        table:"Tabelle",
        userEmail:"Benutzer E-Mail",
        thisWeek:"Diese Woche",
        update:"Update",
        refresh:"Erneuern",
        upgrade:"Upgrade",
        answered:"Beantwortet",
        doesntAnswered:"Nicht beantwortet",
        yourAnswer:"Ihre Antwort",
        thisMonth:"Diesen Monat",
        question:"Frage",
        emailVerified:"Email verifiziert",
        showInApp:"In der App angezeigt",
        upload:"Upload",
        productOptions:"Produktoptionen (Farbe, Größe,..)",
        newPassword:"Neues Passwort",
        writeCode:" Geben Sie den an Ihre Adresse gesendeten Code ein",
        writeNewPassword:"Neues Passwort eingeben",
        least6Char:" Das Passwort muss mindestens aus 6 Zeichen bestehen",
        passwordReset:" Passwort wurde zurück gesetzt",
        designType:"Design Typ",
        productsBackground:"Hintergrund und Produkte",
        productsBackgroundBrands:"Hintergrund, Produkte und Marken",
        HaveAccount:"Haben Sie kein Konto?",
        selectCategory:"Kategorie auswählen",
        value:"Wert",
        extraPrice:"Extra Preis",
        uploadInfo:" Zum Installieren hierher ziehen oder klicken",
        uploadInfo2:" Sie können mehrere Dateien hochladen",
        logo:"Logo",
        haveAccount:"Haben Sie ein Konto?",
        mobileApp:"Mobile App",
        payments:"Zahlungen",
        logout:"Abmelden",
        orderActive:" Benutzer können eine Bestellung aufgeben",
        offerActive:" Benutzer können eine Bestellung anfodern",
        showPrices:"Preise zeigen",
        yourCompany:"Ihr Unternehmen",
        design:"Design",
        publishTime: "Es kann bis zu 7 Tage dauern, bis Ihre App bei Google Play und im App Store genehmigt wird.",
        dontHaveAccount:"Sie haben kein Konto?",
        verifyEmailaddress:"Verifiziere deine E-Mail",
        verifyEmail:"Verifiziere deine E-Mail",
        password:"Passwort",
        googleIleDevam: "Mith Google fortfahren",
        facebookIleDevam: "Mith Facebook fortfahren",
        appleIleDevam: "Mith Apple fortfahren",
        monthlyPayment:"Monatlich Zahlen",
        yearlyPayment:"Jährlich Zahlen",
        plans:"Preispläne",
        month:"Monat",
        months:"Monate",
        total:"Gesamtsumme",
        publishableKey:"Veröffentlichbarer Schlüssel",
        secretKey:"GeheimSchlüssel",
        clientId:"Kunden-ID",
        clientSecret:"Client-Geheimnis",
        errorMessage:"Es ist ein Fehler aufgetreten",
        results:"Ergebnisse",
        brands:"Marken",
        categories:"Categorien",
        comments:"Bewertungen",
        revenue:"Einnahmen",
        orders:"Bestellungen",
        newCustomers:"Neue Kunden",
        newOrders:"Neue Bestellungen",
        bestSellingProducts:"Meistgekaufte Produkte",
        paymentOptions:"Zahlungsarten",
        home:"Startseite",
        name:"Name",
        lastname:"Nachname",
        email:"E-mail",
        date:"Datum",
        price:"Preis",
        products:"Produkte",
        shopSettings:"Shop-Einstellungen",
        shop:"Shop",
        search:"Suche",
        reset:"Zurücksetzen",
        period:"Zeitraum",
        questions:"Fragen",
        order:"Bestellen",
        contact:"Kontakt",
        settings:"Einstellungen",
        users:"Benutzer",
        completed:"Abgeschlossen",
        shipping:"Versand",
        preparing:"Vorbereitung",
        new:"Neu",
        cancel:"Abbrechen",
        paymentStatus:"Zahlungsstatus",
        paid:"Bezahlt",
        unpaid:"Unbezahlt",
        status:"Status",
        orderCreated:"Bestellung erstellt",
        orderUpdated:"Bestellung aktualisiert",
        paymentState:"Zahlungsstatus",
        paymentMethod:"Zahlungsart",
        offer:"Angebot",
        address:"Adresse",
        phone:"Telefon",
        fullAddress:"Vollständige Adresse",
        postcode:"PLZ",
        city:"Stadt",
        all:"Alle",
        forgetPassword:"Passwort vergessen?",
        login:"Anmelden",
        signup:"Registrieren",
        verify:"Verifizieren",
        details:"Einzelheiten",
        deleteConfirm:"Sind Sie sicher, dass Sie löschen möchten?",
        brand:"Marke",
        image:"Bild",
        delete:"Löschen",
        reOrder:"Sortieren",
        today:"Heute",
        yesterday:"Gestern",
        businessName:"Firmenname",
        street:"Straße",
        number:"Hausnr.",
        active:"Aktiv",
        stock:"Lager",
        title:"Titel",
        save:"Speichern",
        code:"Code",
        currency:"Währung",
        codeFalse:"Sie haben den Code falsch eingegeben",
        weSentCode:"Wir haben einen bestätigungscode gesendet",
        passwordAgain:"Passwort wiederholen",
        passwordSame:"Passwörter stimmen nicht überein",
        backgroundColor:"Hintergrundfarbe",
        background:"Hintergrund",
        description:"Beschreibung",
        unlimited:"Unbegrenzt",
        tryFree:"Kostenlos testen",
        or:"oder",
        checkInfo:"Prüfen Sie Ihre Informationen",
        category:"Kategorie",
        add:"Hinzufügen",
        emailAlreadyUsing:"E-Mail-Adresse wird bereits verwendet",
        later:"später",
        continue:"Weiter",
        appPublish: "App veröffentlichen",
        publish: "Veröffentlichen",
        statusBarIconColorBlack: "Schwarze Statusleistensymbole",
        textColor: "Text-Farbe",
        primaryColor: "Primärfarbe",
        requestProcessed: "Wir bearbeiten Ihre Anfrage",
        publishNow: "Jetzt veröffentlichen",
        back: "Zurück",
        edit: "Bearbeiten",
        googlePlayInfo: "Ihre Google Play-Informationen",
        appstorePlayInfo: "Ihre Appstore-Informationen",
        packageOk: "Das von Ihnen verwendete Paket ist kompatibel",
        packageNotOk: "Das von Ihnen verwendete Paket ist unzureichend.  Bitte upgraden Sie auf Enterprise-Paket.",
        packageNotOkPremium: "Das von Ihnen verwendete Paket ist unzureichend. Bitte upgraden Sie auf Premium-Paket.",
        processingAppOk: "Die Prozesse Ihrer App ist bei Google Play und im App Store fertiggestellt",
        processingApp: " Die Prozesse Ihrer App führen bei Google Play und im App Store weite ",
        publishOwnName: "Ich möchte mit meinem Firmennamen auf Google Play und im App Store veröffentlichen",
        publishAppmee: "Ich möchte in der Appmee-App veröffentlichen",
        unPublish: "Veröffentlichung aufheben",
        publishOnAppmee: "In der Appmee-App veröffentlicht.",
        givePermission: "Autorisieren Sie die folgende E-Mail-Adresse über Ihr Google Play Console-Konto.",
        howItWorks:"Wie funktioniert es?",
        level1:"Registrieren Sie sich auf der Seite und geben Sie dann die Produkte, Kategorien und Ihre Firmendaten ein.",
        level2: "Passen Sie die Farben, das Betriebssystem und die Zahlungsmethode Ihrer Anwendung nach Ihren Wünschen an.",
        level3: "Veröffentlichen Sie im letzten Schritt Ihre Website oder App. Sie haben zwei Möglichkeiten, Ihre App zu veröffentlichen. Wenn Sie möchten, können Sie eine Anwendung mit Ihrem eigenen Firmennamen im Appstore und bei Google Play veröffentlichen. Wenn Sie möchten, können Sie es auch in der Appme-App im Appstore und bei Google Play veröffentlichen.",
        sale: "Rabatt Prozent",
        redirectCname: "Ersetzen Sie diese durch CNAME von Appmee",
        domain: "Domain",
        nameserverInfo: "Registrare benötigen für die Bearbeitung von Nameserver-Updates bis zu 24 Stunden",
        manageYourWebsite: "Verwalten Sie Ihre Website",
        manageYourApp: "Verwalten Sie Ihre App",
        terms:"AGB",
        privacy:"Datenschutz",
        legal:"Impressum",
        customer: "Kunde",
        product: "Produkt",
        review: "Bewertung",
        star: "Stern",
        images: "Bilder",
        logoSquare: "Logo (Quadrat)",
        setupNotIncluded: "Die Installation ist nicht enthalten",
        setupIncluded: "Die Installation ist inbegriffen",
        wantInstall: "Ich möchte es installiert haben",
        approved: "Bestätigt",
        addSpecifyPricing: "Sonderpreis hinzufügen",
        specifyPricing: "Sonderpreis",
        discounts: "Rabatte",
        discountProductInfo: "Wenn Sie ein Produkt auswählen, kann der Rabatt nur für das ausgewählte Produkt verwendet werden",
        discountCategoryInfo: "Wenn Sie eine Kategorie auswählen, kann der Rabatt nur für Produkte in der ausgewählten Kategorie verwendet werden",
        discountUserInfo: "Wenn Sie einen Benutzer auswählen, kann der Rabatt nur von dem ausgewählten Benutzer verwendet werden",
        notificationMessage: "Benachrichtigungstext",
        minPrice: "Mindestbetrag im Warenkorb",
        fixed: "Fixierter Rabatt",
        percent: "Verhältnis",
        notifications:"Benachrichtigungen",
        sendNotification: "Benachrichtigung senden",
        defaultSelected: "Standard ausgewählt",
        orderWithoutPayment: "Kann ohne Bezahlung bestellt werden",
        totalOrders: "Alle Bestellungen",
        monthlyOrders: "Monatliche Bestellungen",
        weeklyOrders: "Wöchentliche Bestellungen",
        cancelUser: "Benutzer kündigen",
        approveUser: "Benutzer bestätigen",
        discountCoupon: "Rabattgutschein",
        shippingCost: "Versandkosten",
        freeShipping: "GRATIS Lieferung",
        freeShippingCostMinAmount: "Mindestbestellmenge für kostenlosen Versand",
        specialShippingCost: "Ich möchte einen speziellen Versandpreis für das Produkt festlegen",
        freeShippingCostInfo: "Wenn Null eingegeben wird, wird allen Bestellungen eine Versandgebühr hinzugefügt. Wenn zum Beispiel 10€ eingegeben wird, sind alle Bestellungen über 10€ versandkostenfrei.",
        priceColor: "Preis Farbe",
        discountColor: "Rabatt Farbe",
        trackingNumber: "Sendungsnummer"


    }
  }
})
