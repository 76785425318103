<template>
  <a-popover trigger="click">
    <template #content>
      <a-form style="width: 200px" layout="vertical">
        <a-form-item :label="$t('offer')" >
          <a-input-number v-model:value="offer"></a-input-number>
          <span class="ml-2">{{(((offer??0) < 0 ? 0:(offer??0))/100).toFixed(2) + ' '+ $store.configStore.user.config.currency}}</span>
        </a-form-item>
        <a-button type="primary" @click="updateOffer" :loading="loading">
          <template #icon>
            <check-outlined/>
          </template>
          {{$t('save')}}
        </a-button>
      </a-form>
    </template>
    <a-button size="small" >
      {{$t('offer')}}
      <template #icon>
        <percentage-outlined/>

      </template>
    </a-button>
  </a-popover>
</template>

<script>
import {post} from "@/helper/api";
import {CheckOutlined,PercentageOutlined} from "@ant-design/icons-vue";

export default {
  name: "update_offer",
  components:{CheckOutlined,PercentageOutlined},
  props:{
    order:Object,
    getItems:Function
  },
  data(){
    return {
      loading:false,
      offer:this.order.offer
    }

  },
  methods:{
    async updateOffer(){
      this.loading = true
      await post('order/offer',{
        id:this.order.id,
        offer:this.offer
      })
      this.loading=false
      this.getItems()

    }
  }
}
</script>

<style scoped>

</style>