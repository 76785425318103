<script setup>
import { CheckOutlined,CloseOutlined, EditOutlined, EllipsisOutlined,DeleteOutlined } from '@ant-design/icons-vue';
import {FormModalStoreProduct} from "@/stores/modal";
const formModalStore  = FormModalStoreProduct()
</script>
<template>

  <a-card   hoverable >
    <template  #cover>
      <div class="crud-image" @click="formModalStore.openWithData(data)">
        <img
              :src="'https://cdn.bringsdelivery.de/'+data.thumbnail"
        />
      </div>

    </template>
    <template #actions>
      <a-switch  v-model:checked="data.active" @change="changeStatus()">
        <template #checkedChildren><check-outlined /></template>
        <template #unCheckedChildren><close-outlined /></template>
      </a-switch>
      <EditOutlined @click="formModalStore.openWithData(data)" key="edit" />
      <a-dropdown >
        <EllipsisOutlined />
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a style="color: red">
                <DeleteOutlined /> {{this.$t('delete')}})
              </a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>

    </template>
    <a-card-meta  @click="formModalStore.openWithData(data)" :title="data.title" :description="data.shortDescription">

    </a-card-meta>
  </a-card>

</template>

<script>

import {post} from "../../../../helper/api";

export default {
  name: "single",
  props:{
    data:null
  },
  methods:{
    changeStatus(){

      post('product/status',{id:this.data.id,active:this.data.active})

    }
  }

}
</script>

<style lang="sass">
.crud-image
  display: flex
  justify-content: center
  img
    max-width: 135px
    padding: 8px
</style>