<template>
  <a-form
      :model="formState"
      name="basic"
      layout="vertical"
      autocomplete="off"
      @finish="onFinish(formState)"
      @finishFailed="onFinishFailed"
  >
    <a-form-item
        :label="$t('email')"
        name="email"
        :rules="[{ required: true }]"
    >
      <a-input v-model:value="formState.email"/>
    </a-form-item>

    <a-form-item
        style="margin-bottom: 16px"
        :label="$t('password')"
        name="password"
        :rules="[{ required: true}]"
    >
      <a-input-password v-model:value="formState.password"/>
    </a-form-item>
    <div class="is-flex is-justify-content-end">
      <router-link :to="{name:'forgetPassword'}">
        <a-button type="link" class="pr-0">
          {{$t('forgetPassword')}}
        </a-button>
      </router-link>


    </div>


    <a-form-item

    >
      <a-button :loading="loading" style="margin-top: 5px" class="login-form-button" type="primary" html-type="submit">
        {{$t('login')}}
      </a-button>



    </a-form-item>
  </a-form>
</template>

<script>
import {defineComponent, reactive} from "vue";
import {post} from "../../../helper/api";

import {displayErrorMessage} from "../../../helper/messages";

import {LoadingOutlined} from "@ant-design/icons-vue";

const sleep = m => new Promise(r => setTimeout(r, m))
import {ConfigStore} from "@/stores/config";
import Social_login_buttons from "../social_login_buttons.vue";


export default defineComponent({
  metaInfo: {
    title: 'login_form'
  },
  setup() {

    const configStore = ConfigStore();

    const formState = reactive({
      email: '',
      password: ''
    });


    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      configStore,
      onFinishFailed
    };
  },
  components: {LoadingOutlined,Social_login_buttons},
  async mounted() {
    let jwt = localStorage.getItem('token');
    if (jwt != null) {

      this.$router.push({'name': 'dashboard_home'})
    }


  },
  data() {
    return {
      loading: false
    }
  },

  methods: {


    async onFinish(formState) {


      this.loading = true
      await sleep(500)
      let result = await post("login", {
        "email": formState.email,
        "password": formState.password
      })

      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }


      if (!result.json.error) {
        localStorage.setItem('token', result.json.token)
        this.$router.push({name: 'dashboard_home'})
      } else {
        //console.log(result.json)
        if (result.json.error === '') {
          displayErrorMessage()
        }
        if (result.json.error === 'steps') {
          localStorage.setItem('tempToken', result.json.token)
          localStorage.setItem('verifyEmail', formState.email)


          this.$router.push({name: 'signupSteps'})
        }
        if (result.json.error === 'info') {
          displayErrorMessage(this.$t('checkInfo'))
        }

        this.loading = false
      }

    },

  }


});


</script>
<style lang="sass">
.social-icons
  display: flex
  flex-direction: column


  .button-box
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    background-color: rgba(246, 246, 246, 0.73)
    border-radius: 12px
    margin-bottom: 8px

    &:hover
      background-color: #f6f6f6
      cursor: pointer
      transition: .5s
      border-radius: 12px

  img
    width: 30px
    margin: 8px

.sign-up-text
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  margin-top: 12px

.login-form-button
  width: 100%

.vertical-divider
  display: flex
  justify-content: center
  color: gray
  margin: 12px 0

.login

  @media (min-width: 768px)
    margin: 35px

  .columns
    display: flex
    justify-content: center

  .login-box
    border: 1px solid #d8d8d8
    padding: 35px 35px 12px 35px

    .login-form-button
      margin-top: 25px


    .icon
      font-size: 16px
      margin-right: 8px


</style>



