<template>
  <a-form
      :model="formState"
      name="basic"
      layout="vertical"
      autocomplete="off"
      @finish="onFinish(formState)"
      @finishFailed="onFinishFailed"
  >
    <a-form-item
        :label="$t('businessName')"
        name="businessName"
        :rules="[{ required: true }]"
    >
      <a-input v-model:value="formState.businessName" />
    </a-form-item>
    <a-form-item
        :label="$t('address')"
        name="address"
    >
      <a-textarea v-model:value="formState.address" />
    </a-form-item>





    <a-form-item>
      <a-button :loading="loading" class="login-form-button" type="primary" html-type="submit">{{ $t('save') }}</a-button>


    </a-form-item>
  </a-form>
</template>

<script>
import {defineComponent, reactive} from "vue";

import { message } from 'ant-design-vue';
import {displayErrorMessage} from "@/helper/messages";

const sleep = m => new Promise(r => setTimeout(r, m))
import {ConfigStore} from "@/stores/config";
import {post} from "../../../helper/api";


export default defineComponent({
  metaInfo: {
    title: 'user_info_form'
  },
  setup() {
    const configStore = ConfigStore();

    const formState = reactive({
      businessName: '',
    });


    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      configStore,
      onFinishFailed,
    };
  },
  mounted() {

  },
  data(){
    return {
      loading:false
    }
  },
  methods:{


    async onFinish(formState){

      this.loading=true
      await sleep(500)
      let result = await post("config",{
        businessName:formState.businessName
      })
      let resultUser = await post("user",{
        name:formState.name,
        lastname:formState.lastname,
      })

      if(!result.ok){
        displayErrorMessage()
        this.loading=false
        return;
      }


      if(!result.json.error){
        this.$router.push({ name: 'dashboard_home' })
      }else{
        //console.log(result.json)
        if(result.json.error === ''){
          displayErrorMessage()
        }


        this.loading=false
      }

    }
  }


});
</script>

<style scoped>

</style>