<template>
  <div class="dashboard-navbar">
    <div   @click="$store.configStore.toggleMenu()" class="mobile-menu-icon">
      <MenuOutlined />
    </div>
    <a-dropdown class="account-avatar m-2" v-if="$store.configStore.user.email.length>0">

      <a-avatar v-if="$store.configStore.user.thumbnail==null" style="color: #038100; background-color: rgba(46,206,60,0.29)">
        <span >
           {{$store.configStore.user.email[0].toUpperCase()}}
        </span>

      </a-avatar>
      <div v-else class="m-2" style="height: 32px;width:32px">
        <img referrerpolicy="no-referrer" style="border-radius:50px" :src="$store.configStore.user.thumbnail">
      </div>

      <template #overlay>
        <div class="user-dropdown">
          <a-avatar v-if="$store.configStore.user.thumbnail==null" style="color: #038100; background-color: rgba(46,206,60,0.29)">
              <span >
                 {{$store.configStore.user.email[0].toUpperCase()}}
              </span>

          </a-avatar>
          <div v-else class="m-2" style="height: 32px;width:32px">
            <img referrerpolicy="no-referrer" style="border-radius:50px" :src="$store.configStore.user.thumbnail">
          </div>

          <div class="company-name">{{$store.configStore.user.name + ' ' + $store.configStore.user.lastname}}</div>
          <div  v-if="$store.configStore.user.facebookId == null" class="email mb-1">
            {{$store.configStore.user.email}}
          </div>




          <a-button danger block @click="logout"> {{$t('logout')}} </a-button>

        </div>
      </template>
    </a-dropdown>
  </div>

</template>
<style lang="sass" scoped>
.plan-name
  border-radius: .3rem
  background-color: #039e0d
  color: white
  font-size: 14px
  padding: 4px 14px
  display: flex
  justify-content: center
  align-items: center
.account-avatar
  &:hover
    cursor: pointer
.dashboard-navbar
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  background-color: white

.mobile-menu-icon
  margin: 12px
  padding: 8px 12px
  border-radius: 5px
  &:hover
    background-color: #e2e2e2
    cursor: pointer


.user-dropdown
  width: 300px
  padding: 12px
  background-color: white
  box-shadow: 0px 0px 3px #d4d4d4
  margin-top: 12px
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  .company-name
    font-size: 18px
  .email
    font-weight: 400
  .filter-item
    margin-bottom: 8px
</style>

<script>


import {MenuOutlined} from "@ant-design/icons-vue";
import {ConfigStore} from "@/stores/config";


export default {
  name: 'NavBar',
  components:{
    MenuOutlined
  },
  setup(){
    const configStore = ConfigStore()
    return {configStore}
  },

  mounted() {
    let jwt = localStorage.getItem('token');
    console.log(jwt)
    if(jwt == null){
      this.$router.push('/login')
    }else{
      this.$store.configStore.getCurrentUser()

    }
  },
  props: {
    customContent: {
      type: String,
      default: 'default Content',
    },
  },
  data(){
    return {
      user:null
    }
  },
  methods:{
    logout(){
      localStorage.removeItem('token')
      this.$router.push({path:'/login'})
    },

  }

};
</script>