


<script>

import LeftMenu from '@/components/dashboard/left_menu.vue'
import Navbar from '@/components/dashboard/navbar.vue'


export default {
  components:{
    LeftMenu,
    Navbar
  },
  mounted() {
    this.isMobile = window.innerWidth<768 ? 0:80
  },
  data() {
    return {
      loading:false,
      isMobile:false
    }
  }
}
</script>


<template>
  <progress :class="loading ? 'is-active':''" class="progress  is-primary top-progress" max="100"></progress>
<div class="container is-fluid p-0 admin-area">



    <LeftMenu/>


  <div :style="'calc(100% - '+this.$store.configStore .menuCollapsed ? ';width: '+(isMobile).toString()+'px':'width: 256px'+')'" class="root-content">

    <Navbar   />



      <router-view v-slot="{ Component, route }">
        <transition name="fade" mode="out-in">
          <div class="page-content" :key="route.name">
            <component :is="Component"></component>
          </div>
        </transition>
      </router-view>

  </div>
</div>
</template>



<style lang="sass" scoped>
@import "../../assets/page/admin"

.page-content
  @media (max-width: 768px)
    position: absolute
    width: 100vw


body
  background-color: #f5f5f5
.top-progress
  position: absolute
  height: 5px
  border-radius: 100px
  display: none
  transition: .3s
  &.is-active
    display: block
.admin-area
  display: flex
  flex-direction: row
  .left-menu

    .menu
      position: fixed
  .root-content
    flex: 1



</style>
<style>

.root-content{
  background-color: #f0f2f5;
  min-height: 100vh;
  z-index: 100;
}



.fade-enter-from {
  opacity: 0;
  transform: translateX(20px)
}

.fade-enter-active {
  transition: all 0.2s ease-out;
}

.fade-leave-to {
  opacity: 0;
  transform: translateX(-20px)
}

.fade-leave-active {
  transition: all 0.2s ease-in
}


</style>