<script setup>

import CrudHeader from '@/components/dashboard/crud/header.vue'
import CrudIndex from '@/components/dashboard/crud/user/index.vue'
</script>
<script>
import {useMeta} from "vue-meta";

export default {
  data() {
    return {
      items:[]
    }
  },
  mounted() {
    useMeta({
      title: this.$t('users')
    })
  }
}
</script>


<template>

  <CrudHeader title="users" />




  <CrudIndex />

</template>



<style lang="sass">
.crud-title
  font-size: 20px
  padding: 12px

</style>