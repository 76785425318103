<template>

  <div  class="has-background-white navigation-shadow"></div>
  <div style="height: 100px" class="has-background-white">

  <nav style="flex-direction: column;align-items: center" class="navbar container is-fullhd is-fixed-top" role="navigation" aria-label="main navigation">
    <div style="display: flex;align-items: center" class="navbar-brand pl-2">
      <a href="https://appmee.de">
        <img  style="height: 85px;padding: 8px" src="https://cdn.appmee.de/AH6B01673291806.png">
      </a>


    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">




      </div>


    </div>
  </nav>
  </div>
</template>
<style lang="sass">
.navigation-shadow
  height: 100px
  position: fixed
  width: 100%
  z-index: 1
  box-shadow: rgb(0 0 0 / 6%) 0 15px 15px 0
</style>

<script>


export default {
  name: 'NavBar',
  props: {
    customContent: {
      type: String,
      default: 'default Content',
    },
  },
};
</script>